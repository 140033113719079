<template>
    
    <div>
        <el-container>
        <el-aside width="auto">
            <common-Aside/>
        </el-aside>
        <el-container>
            <el-header>
                <common-Header />
            </el-header>
            <el-main>
                <!-- <h1>我是main</h1> -->
                
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive" /> 
                
            </el-main>
        </el-container>
        </el-container>
        
    </div>
    
</template>

<script>
import commonAside from '../components/commonAside.vue'
import commonHeader from '../components/commonHeader.vue'
export default {
    data(){
        return{}
    },
    components:{
        commonAside,
        commonHeader
    },
    created(){
        // 设置刷新界面跳转指定路由
        this.$router.push({
            path: '/'
        });
    }
}

</script>

<style lang="less" scoped>
.el-header {
    padding: 0px;
}

</style>