import {http, http_file} from '../utils/request'

// 首页信息接口
export const getData = () => {
    // 返回一个 promise 对象
    return http.get('/admin/index_info')
}

// 习题数据接口
export const Getxiaoche = (params) => {
    console.log(params, 'params')
    return http.get('/admin/exercise', params)
}

// 习题选项分类接口
export const searchSelect = (params) => {
    console.log(params, 'params')
    return http.get('/admin/search_select', params)
}

// 模拟考试习题接口
export const parcticeinfo = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/parcticeinfo', params)
}

// 修改习题位置接口
export const setlocation = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/location', data)
}

// 删除习题接口
export const delproblem = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/delproblem', params)
}

// 查询题型分类和对应的数量接口
export const classification = (params) => {
    return http.get('/admin/classification', params)
}

// 添加题型分类接口
export const addclassification = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/addclassification', data)
}

// 删除题型分类接口
export const delclassification = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/delclassification', params)
}

// 添加题目
export const addproblem = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/addproblem', data)
}

// 上传文件(图片、视频)的接口
// export const uploadFileRequest = (data) => {
//     return http.post('/uploadFile', data, {headers: {'Content-Type': 'multipart/form-data'}})
// }

  // 用户登录接口
export const login = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/login', data)
}

// 修改添加题目的视频或者图片信息
export const update_iamge = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/update_image', data)
}


// 查询题目的技巧和图片视频资源
export const search_skill = (params) => {
    return http.get('/admin/search_skill', params)
}


// 删除图片视频资源
export const update_skill = (data) => {
    return http.post('/admin/search_skill', data)
}


// 修改技巧信息并返回cos临时密钥
export const put_skill = (data) => {
    return http.put('/admin/search_skill', data)
}


// 查询题目的技巧和图片视频资源
export const secretkey = (params) => {
    return http.get('/admin/secretkey', params)
}


// 添加激活码接口
export const addactivation = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/addactivation', data)
}


// 查询激活码接口
export const activation = (params) => {
    return http.get('/admin/activation', params)
}


// 修改题型分类接口
export const edit_subject_type = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/edit_subject_type', data)
}


// 删除激活码接口
export const delactivation = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/delactivation', params)
}

// 禁用或开启激活码接口
export const update_activation = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/update_activation', params)
}

// 微信调用获取刷题信息信息得接口
export const parcticeinfo_weixin = (params) => {
    // console.log(params, 'params')
    return http.get('/weixin/parcticeinfo_pc', params)
}


// 记录答题记录
export const answer_question_pc = (data) => {
    // console.log(params, 'params')
    return http.put('/weixin/answer_question_pc', data)
}


// 获取用户信息接口
export const user_info = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/userInfo', params)
}


// 下载不同分类得题目信息
export const download_exercise = (params) => {
    // console.log(params, 'params')
    return http_file.get('/admin/download_exercise', params)
}

// 上传文件表格xlsx
export const add_exercise_excel = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_exercise_excel', data)
}

// 查询表格上传处理进度
export const search_excel_progress = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/search_excel_progress', params)
}


// 修改用户基本信息
export const change_user_info = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/change_user_info', data)
}


// 查询用户基本信息
export const admin_info = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/admin_info', params)
}


// 发送注册短信激活码
export const send_sms_api = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/send_sms', params)
}


// 修改用户基本信息
export const add_app_user = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_app_user', data)
}


// 查询桌面应用用户信息
export const app_user = (params) => {
    return http.get('/admin/app_user', params)
}


// 查询桌面应用单个用户信息
export const one_app_user = (params) => {
    return http.get('/admin/one_app_user', params)
}

// 更新桌面应用单个用户信息
export const update_app_user = (data) => {
    return http.post('/admin/one_app_user', data)
}

// 删除软件用户接口
export const del_app_user = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/del_app_user', params)
}

// 删除习题接口
export const set_app_user_del = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/set_app_user_del', params)
}

// 创建机器码
export const create_code = (params) => {
    return http.get('/admin/create_code', params)
}


// 查询机器码信息
export const machine_code = (params) => {
    return http.get('/admin/machine_code_info', params)
}

// 修改机器码备注信息
export const update_app_code = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/update_app_code', data)
}


// 删除机器码激活码
export const del_app_code = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/del_app_code', params)
}


// 软件用户得详细修改记录
export const app_user_change = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/app_user_change', params)
}


// 软件用户得学科详细信息
export const app_subjects_detail = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/app_subjects_detail', params)
}


// 修改软件用户得学科
export const app_subjects_set = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/app_subjects_set', params)
}


// 查询桌面应用用户信息
export const app_user_study = (params) => {
    return http.get('/admin/app_user_study', params)
}


// 查询桌面应用用户订单信息
export const app_user_order = (params) => {
    return http.get('/admin/app_user_order', params)
}


// 新增管理员信息
export const add_admin = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_admin', data)
}

// 查询桌面应用用户信息
export const admin_user_info = (params) => {
    return http.get('/admin/admin_user_info', params)
}


// 更新管理员用户信息
export const update_admin_user = (data) => {
    return http.post('/admin/admin_user_info', data)
}


// 删除习题接口
export const del_admin_user = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/del_admin_user', params)
}


// 学员考试成绩查询接口
export const app_user_exam = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/app_user_exam', params)
}


// 添加学员预约考试时间
export const add_exam_log = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_exam_log', data)
}


// 查询学员预约考试时间
export const search_exam_log = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/add_exam_log', params)
}


// 修改学员预约的考试状态
export const set_exam_log = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/set_exam_log', params)
}


// 新增老师信息
export const add_teacher = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_teacher', data)
}

// 用户订单退费接口
export const refund = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/refund', params)
}


// 用户订单更改费用问题
export const update_refund = (data) => {
    return http.post('/admin/refund', data)
}

// 查询全部预约考试信息
export const full_examination = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/full_examination', params)
}


// 查询全部预约考试信息已挂科的信息
export const full_examination_fail = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/full_examination_fail', params)
}

// 单独查询每个老师的数据概览
export const one_teacher_overview = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/one_teacher_overview', params)
}


// 修改微信激活码备注
export const update_wx_code_remark = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/update_wx_code_remark', data)
}


// 添加桌面应用激活码有效期
export const update_app_code_validity = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/update_app_code_validity', data)
}


// 查询成员下面的老师信息
export const teacher_name = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/teacher_name', params)
}


// 查询VIP权限数据接口
export const wechat_vip = (params) => {
    return http.get('/admin/wechat_vip', params)
}

// 添加VIP分类接口
export const add_vip = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/add_vip', data)
}

// 修改题VIP分类接口
export const put_vip = (data) => {
    // console.log(params, 'params')
    return http.put('/admin/add_vip', data)
}

// 删除VIP分类接口
export const del_vip = (params) => {
    // console.log(params, 'params')
    return http.delete('/admin/add_vip', params)
}

// 设置VIP推广返点比例
export const vip_scale = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/vip_scale', data)
}

// 设置VIP推广返点比例
export const get_scale = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/vip_scale', params)
}


// 获取用户的VIP订单信息
export const get_vip_order = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/get_vip_order', params)
}

// 获取用户的VIP订单信息
export const vip_kickback = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/vip_kickback', params)
}


// 修改VIP订单的vip时长
export const extend_vip = (data) => {
    // console.log(params, 'params')
    return http.post('/admin/extend_vip', data)
}


// 理论删除微信用户接口
export const del_wechat_user = (params) => {
    // console.log(params, 'params')
    return http.get('/admin/del_wechat_user', params)
}