<template>
    <div class="manage">
      <div class="manage-header">
  
        <!-- form搜索区域 -->
        <el-form :inline="true" :model="userForm">
          <el-form-item>
            <el-input placeholder="激活码" v-model="userForm.code" clearable ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="备注" v-model="userForm.remarks" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="机器码" v-model="userForm.machine_code" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
  
        <el-button @click="handleAdd" type="primary">
          + 新增
        </el-button>
      </div>
      <div class="common-tabel">
        <el-table height="90%" :data="tableData" style="width: 100%" v-loading="loading"
          element-loading-text="拼命加载中" :row-class-name="tableRowClassName">
          <el-table-column type="index" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="code" label="激活码" width="220">
          </el-table-column>
          <el-table-column prop="machine_code" label="机器码" width="220">
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="100">
          </el-table-column>
          <el-table-column prop="create_time" label="创建时间" width="180">
          </el-table-column>
          <el-table-column prop="validity_day" label="有效期(天)" width="100">
          </el-table-column>
          <el-table-column prop="end_time" label="到期时间" width="180">
          </el-table-column>
          <el-table-column prop="log_time" label="上次在线时间" width="180">
          </el-table-column>
          <el-table-column prop="log_address" label="上次在线地址" width="150">
          </el-table-column>
          <el-table-column prop="log_status" label="状态" width="50">
            <template slot-scope="scope">
                        <div class="light-green" v-show="scope.row.log_status == 'T'"></div>
                        <div class="light-grey" v-show="scope.row.log_status == 'F'"></div>
               </template>
          </el-table-column>
          <el-table-column prop="addr" label="操作" width="350" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="handleClick(scope.row)"><i class="el-icon-copy-document"></i>复制</el-button>
              <el-button type="success" size="mini" @click="upload(scope.row)"><i class="el-icon-edit"></i>备注</el-button>
              <el-button type="warning" size="mini" @click="set_day(scope.row)"><i class="el-icon-edit"></i>调时</el-button>
              <el-button type="danger" size="mini" @click="handleDelete(scope.row)"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage1"
            :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  <script>
  // import { getUser, addUser, editUser, delUser } from '../api'
  import { del_app_code, create_code , machine_code, update_app_code, update_app_code_validity} from '../api'
  
  const config = {
    // 存储桶名称
    Bucket: 'subject-picture-1257386772',
    // 地域
    Region: 'ap-nanjing'
  }
  
  
  export default {
    data() {
      return {
        dialogVisible: false,
        loading: true,
        currentPage1: 1,
        video_url: '',
        
        tableData: [],
        modalType: 0, // 0表示新增的弹窗， 1表示编辑
        total: 0, //当前的总条数
        pageData: {
          page: 1,
          limit: 10
        },
        userForm: {
          code: '',
          remarks: '',
          machine_code: '',
        },
      }
    },
    methods: {
      // 弹窗关闭的时候
      handleClose() {
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      cancel() {
        this.handleClose()
      },
      handleDelete(row) {
        this.$confirm('此操作将永久删除该激活码,并使已激活的用户功能失效, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            del_app_code({ params: row }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
  
            // 重新获取列表的接口
            this.getList()
          })
  
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleAdd() {
        create_code({ }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
            // 重新获取列表的接口
            this.getList()
        })
      },
      // 获取列表的数据
      getList() {
        // 获取的列表的数据
        machine_code({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
          console.log(data)
          this.tableData = data.data
  
          this.total = data.count || 0
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageData.limit = val
        this.getList()
  
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageData.page = val
        console.log(this.pageData)
        this.getList()
  
      },
      // 选择页码的回调函数
      handlePage(val) {
        // console.log(val, 'val')
        this.pageData.page = val
        this.getList()
      },
      // 列表的查询
      onSubmit() {
        // this.pageData.page = 1
        this.getList()
      },
      upload(val) {
        console.log(val)
        this.$prompt('请输入备注信息', '备注信息', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
        }).then(({ value }) => {
            update_app_code({ data: val, uuid: value }).then(({ data }) => {
                if (data.code == 200) {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: data.msg
                    });
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '取消更改'
            });
        });
      },
      handleClick(val) {
        console.log(val)
        console.log('复制')
        var input = document.createElement("input"); // 创建input对象
        input.value = val.code; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success('复制成功!')

      },
      tableRowClassName({row, rowIndex}) {
        // 根据状态显示不同的行样式
        // console.log(row)
        // console.log(rowIndex)
        if (row.validity === 'F') {
          return 'warning-row';
        }
        return '';
      },
      set_day(val) {
            console.log(val)
            this.$prompt('请输入需要添加多少天', '添加时长', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
              update_app_code_validity({ data: val, day: value }).then(({ data }) => {
                    if (data.code == 200) {
                      this.getList()
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消更改'
                });
            });

        },
  
    },
    mounted() {
        machine_code({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
            this.total = data.count
            this.loading = false
        })
    }
  }

  
</script>

<style>
  .el-table .warning-row {
    background:	#CDB38B;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>

<style lang="less" scoped>
  .manage {
    height: 100%;
  
    .manage-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .common-tabel {
      position: relative;
      height: calc(100% - 62px);
  
      .pager {
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
    .light-green {
      width: 20px;
      height: 20px;
      background-color:#008B45;
      border-radius: 50%;
      display: inline-block;
    }

    .light-grey {
      width: 20px;
      height: 20px;
      background-color:#696969;
      border-radius: 50%;
      display: inline-block;
    }

      
  }
</style>