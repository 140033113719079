import axios from 'axios'
import { Notification, MessageBox, Message } from 'element-ui'
import Cookie from 'js-cookie'
import router from '../router'

const BASEURL = 'http://api.yidiantong.wang'
// baseURL: 'http://api.yidiantong.wang' 易点通服务器地址
// baseURL: 'https://api.trybest.top' 自己服务器地址
// baseURL: 'http://127.0.0.1:5000'      本地开发地址
const http = axios.create({
    // 地址前缀(服务器地址)
    baseURL: BASEURL, 
    // baseURL: '/api',
    // 超时时间(毫秒)
    retry: 3, //设置全局重试请求次数（最多重试几次请求）
    retryDelay: 1000, //设置全局请求间隔
    timeout:  1000 * 60,
})

const http_file = axios.create({
  baseURL: BASEURL, 
  retry: 3, //设置全局重试请求次数（最多重试几次请求）
  retryDelay: 1000, //设置全局请求间隔git 
  timeout:  1000 * 60,
  responseType: 'blob',
})

// 添加请求拦截器
http.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    if (Cookie.get('admin_token')){
      config.headers['Authorization'] = 'Bearer ' + Cookie.get('admin_token')
    }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加请求拦截器
http_file.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if (Cookie.get('admin_token')){
    config.headers['Authorization'] = 'Bearer ' + Cookie.get('admin_token')
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    const code = response.data.code
    if (code === 401) {
        // MessageBox.confirm(
        //   '登录状态已过期，您可以继续留在该页面，或者重新登录',
        //   '系统提示',
        //   {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }
        // ).then(() => {
        //   Cookie.remove('token')
        //   router.replace('/login')
  
        // }).catch(() => {
        //   Message.error('建议重新登录, 否则不能进行任何有效操作!')
        // })

      
        Message({
            message: '账户过期，请重新登录！',
            type: 'error',
            duration: 5 * 1000
        })
      
        Cookie.remove('admin_token')
        router.replace('/login')
      
      
    } 
    
    
    // else if (code !== 200) {
    //   Notification.error({
    //     title: response.data.msg
    //   })
    //   return Promise.reject('error')
    // } 
    else {
      return response
    }
  }, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  });



export {http , http_file} 