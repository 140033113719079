<template>
  <div class="manage">

    <div class="manage-header">
      <!-- form搜索区域 -->
      <el-form :inline="true" :model="userForm" ref="userForm">

        <el-form-item prop="nickname">
          <el-input placeholder="微信昵称" v-model="userForm.nickname" clearable></el-input>
        </el-form-item>
        <el-form-item prop="remark">
          <el-input placeholder="备注" v-model="userForm.remark" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSubmit">查询</el-button>
          <!-- <el-button type="info" icon="el-icon-refresh-left" @click="resetForm(`userForm`)">重置</el-button> -->
        </el-form-item>
      </el-form>

    </div>
    <div class="common-tabel">
      <el-table :data="tableData" style="width: 100%" height="90%" v-loading="loading" element-loading-text="拼命加载中">
        <el-table-column fixed label="微信头像" width="150">
          <template slot-scope="scope">
            <el-image style="width: 50px; height: 50px; margin-bottom:-4px" :src="scope.row.headimgurl">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="80">
          <template slot-scope="scope">
            {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="nickname" label="微信昵称" width="220">
        </el-table-column>
        <el-table-column prop="vip_status" label="VIP状态" width="120">
          <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.vip_status === '禁用' ? 'danger' : 'success'"
                    disable-transitions>{{scope.row.vip_status}}</el-tag>
                </template>
        </el-table-column>
        <el-table-column prop="status" label="用户类型" width="120">
        </el-table-column>
        <el-table-column prop="promotion_num" label="推广人数" width="110">
        </el-table-column>
        <el-table-column prop="can_withdraw" label="可提现金额" width="140">
        </el-table-column>
        <el-table-column prop="freeze" label="冻结金额" width="140">
        </el-table-column>
        <el-table-column prop="already_withdraw" label="已提现金额" width="140">
        </el-table-column>
        <el-table-column prop="statr_time" label="VIP开始时间" width="160">
        </el-table-column>
        <el-table-column prop="end_time" label="VIP结束时间" width="160">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="upload(scope.row)"><i class="el-icon-open"></i>禁/开</el-button>
            <el-button @click="del_user(scope.row)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pager">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[20, 40, 60, 80, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>


<script>

import { user_info, update_activation, del_wechat_user } from '../api'

export default {
  data() {
    return {
      tableData: [],
      pageData: {
        page: 1,
        limit: 20
      },
      userForm: {
        nickname: '',
        remark: '',
      },
      loading: true,
      currentPage: 1,
      total: 0,
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
      // this.userForm.select = ''
    },

    getExercise() {
      user_info({ params: { ...this.pageData, ...this.userForm } }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.count
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.limit = val
      this.getExercise()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageData.page = val
      console.log(this.pageData)
      this.getExercise()

    },
    onSubmit() {
      console.log(this.userForm)
      this.getExercise()
    },
    handleClick(val){
      this.$message.info('敬请期待')
    },
    upload(row) {
        del_wechat_user({ params: row }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
  
            // 重新获取列表的接口
            this.getExercise()
          })
      },
    del_user(row) {
      del_wechat_user({ params: row }).then(({ data }) => {
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: data.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: data.msg
            });
          }

          // 重新获取列表的接口
          this.getExercise()
      })
    },
  },
  mounted() {
    user_info({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
      console.log(data)
      this.tableData = data.data
      this.total = data.count
      this.loading = false
    })

  }
}

</script>

<style lang="less" scoped>
.manage {
  height: 98%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-input {
      margin-left: 20px;

    }

    .el-button--primary {
      margin-left: 20px;
    }

    .el-cascader {
      width: 250px;
    }

    .el-form--inline {
      padding-top: 20px;
    }
  }

  .common-tabel {
    position: relative;
    height: calc(100% - 62px);

    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }


}
</style>