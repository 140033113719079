<template>
    <div class="manage">
        <div class="manage-header">

            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm" ref="userForm">
                <el-cascader :options="options" :show-all-levels="true" @change="handleChange($event)" ref="cascaderArr"
                    prop="select" clearable>

                </el-cascader>

                <el-form-item prop="uuid">
                    <el-input placeholder="唯一标识" v-model="userForm.uuid" clearable></el-input>
                </el-form-item>
                <el-form-item prop="problem">
                    <el-input placeholder="题干" v-model="userForm.problem" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="onSubmit">查询</el-button>
                    <el-button type="info" icon="el-icon-refresh-left" @click="resetForm(`userForm`)">重置</el-button>
                </el-form-item>
            </el-form>

            <el-button @click="handleAdd" icon="el-icon-reading" type="primary">
                试题模拟
            </el-button>
        </div>

        <div class="common-tabel">

            <el-table v-loading="loading" element-loading-text="拼命加载中" :data="tableData" stripe style="width: 100%;"
                height="90%" :row-style="{ height: '10px' }" highlight-current-row>


                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item label="问题">
                                <span>{{ props.row.problem }}</span>
                            </el-form-item>
                            <el-form-item label="插图">
                                <img :src="props.row.picture" alt="插画" :class="props.row.picture ? incrseP : downP">
                                <span :class="{ Bpirture: props.row.picture !== '' }">暂无</span>
                            </el-form-item>
                            <el-form-item label="选项A">
                                <span :class="{ correct: props.row.option_a.correct === true }">{{
                                    props.row.option_a.info
                                }}</span>
                            </el-form-item>
                            <el-form-item label="选项B">
                                <span :class="{ correct: props.row.option_b.correct === true }">{{
                                    props.row.option_b.info
                                }}</span>
                            </el-form-item>
                            <el-form-item label="选项C">
                                <span :class="{ correct: props.row.option_c.correct === true }">{{
                                    props.row.option_c.info
                                }}</span>
                            </el-form-item>
                            <el-form-item label="选项D">
                                <span :class="{ correct: props.row.option_d.correct === true }">{{
                                    props.row.option_d.info
                                }}</span>
                            </el-form-item>
                            <el-form-item label="答案">
                                <span style="text-decoration: underline;">{{ props.row.answer }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <!-- <el-table-column type="selection" width="55">
            </el-table-column> -->
                <el-table-column type="index" label="序号" width="80">
                    <template slot-scope="scope">
                        {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="唯一标识" prop="uuid" width="180">
                </el-table-column>
                <el-table-column label="车型" prop="car_model" width="120">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="top">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.car_model }}</el-tag>
                        </div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="科目" prop="subjects" width="120">
                </el-table-column>
                <el-table-column label="大分类" prop="category" width="120">
                </el-table-column>
                <el-table-column label="小分类" prop="class_name" width="160">
                </el-table-column>
                <el-table-column label="题型" prop="answer_type" width="160">
                </el-table-column>
                <el-table-column label="题干" prop="problem" :show-overflow-tooltip='true' width="400px">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="260">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="mini"
                            icon="el-icon-edit">编辑</el-button>
                        <el-button @click="location(scope.row)" type="text" size="mini" icon="el-icon-sort"
                            style="color:darkmagenta">调位</el-button>
                        <el-button @click="dati(scope.row)" type="text" size="mini" icon="el-icon-reading"
                            style="color:chocolate">答题</el-button>
                        <el-button @click="delsubject(scope.row)" type="text" size="mini" icon="el-icon-delete"
                            style="color:red">删除</el-button>
                        <el-button type="text" size="mini" disabled>待定</el-button>
                    </template>
                </el-table-column>

            </el-table>

            <div class="pager">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage4" :page-sizes="[20, 40, 60, 80, 100]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>



        </div>
        <div ref="ruleForm">
            <el-dialog title="模拟做题" :visible.sync="centerDialogVisible" width="60%" center @close="bigvclose">

                <el-container>
                    <el-header style="height: 670px">
                        <!-- 主题部分 -->
                        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                            <!-- 循环后端给你的所有题 -->
                            <div class="VSTD_box_item" v-for="(item, index) in question" :key="item.id">

                                <!-- 1 单选 -->
                                <!-- 2 判断 -->
                                <!-- 3 多选 -->
                                <!-- 4 简答 -->

                                <div class="VSTD_box_item_title">

                                    <!-- 题目的信息 -->

                                    <p style="font-weight: 700">
                                        <span v-if="item.questionType == 1"><el-tag type="primary">单选题</el-tag></span>
                                        <span v-if="item.questionType == 2"><el-tag type="warning">判断题</el-tag></span>
                                        <span v-if="item.questionType == 3"><el-tag type="danger">多选题</el-tag></span>
                                        <span class="color_set" v-html=" '第' + item.sequence_number + '题: ' + item.problem"></span>
                                    </p>
                                </div>

                                <el-form-item label="">
                                    <img :src="item.picture" alt="插画" :class="item.picture ? incrseP : downP">
                                </el-form-item>

                                <!-- 如果questionType 等于1 那么他是单选题 -->
                                <!-- 题目绑定的值是 singlevalue  -->

                                <div v-if="item.questionType == 1">
                                    <el-form-item label="" prop="resource">
                                        <el-radio-group v-model="singlevalue" class="VSTD_box_item_select" @input="single"
                                            :disabled="canclick">
                                            <el-radio :label="item.option_a.info">A.{{ item.option_a.info }}
                                                <span v-if="singlevalueicon === item.option_a.info"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="(singlevalueicon !== item.option_a.info) && (singlevalueicon != '')"><i
                                                        class="el-icon-error" style="color:red;"></i></span>

                                            </el-radio><br />
                                            <el-radio :label="item.option_b.info">B.{{ item.option_b.info }}
                                                <span v-if="singlevalueicon === item.option_b.info"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="(singlevalueicon !== item.option_b.info) && (singlevalueicon != '')"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-radio><br />
                                            <el-radio :label="item.option_c.info">C.{{ item.option_c.info }}
                                                <span v-if="singlevalueicon === item.option_c.info"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="(singlevalueicon !== item.option_c.info) && (singlevalueicon != '')"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-radio><br />
                                            <el-radio :label="item.option_d.info">D.{{ item.option_d.info }}
                                                <span v-if="singlevalueicon === item.option_d.info"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="(singlevalueicon !== item.option_d.info) && (singlevalueicon != '')"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-radio><br />
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <!-- 如果questionType 等于2 那么他是判断题 -->
                                <!-- 题目绑定的值是 judgmentvalue  -->

                                <div v-if="item.questionType == 2">
                                    <el-form-item label="" prop="resource">
                                        <el-radio-group v-model="judgmentvalue" class="VSTD_box_item_select"
                                            @input="judgment" :disabled="canclick">
                                            <el-radio label="对">A.{{ item.option_a.info }}
                                                <i class="el-icon-success" style="color:lightgreen;"
                                                    :class="iconstatus1"></i>
                                                <i class="el-icon-error" style="color:red;" :class="iconstatus2"></i>
                                            </el-radio><br />
                                            <el-radio label="错">B.{{ item.option_b.info }}
                                                <i class="el-icon-success" style="color:lightgreen;"
                                                    :class="iconstatus2"></i>
                                                <i class="el-icon-error" style="color:red;" :class="iconstatus1"></i>
                                            </el-radio><br />
                                        </el-radio-group>
                                    </el-form-item>
                                </div>

                                <!-- 如果questionType 等于3 那么他是多选题 -->
                                <!-- 题目绑定的值是 ruleForm.resource[index]  -->

                                <div v-if="item.questionType == 3">
                                    <el-form-item label="" prop="resource">
                                        <el-checkbox-group v-model="multiplevalue" class="VSTD_box_item_select"
                                            :disabled="canclick">
                                            <el-checkbox :label="item.option_a.info">{{ item.option_a.info }}

                                                <span v-if="multipleanswer.includes(item.option_a.info)"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_a.info))"><i
                                                        class="el-icon-error" style="color:red;"></i></span>

                                            </el-checkbox><br />
                                            <el-checkbox :label="item.option_b.info">{{ item.option_b.info }}
                                                <span v-if="multipleanswer.includes(item.option_b.info)"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_b.info))"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-checkbox><br />
                                            <el-checkbox :label="item.option_c.info">{{ item.option_c.info }}
                                                <span v-if="multipleanswer.includes(item.option_c.info)"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_c.info))"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-checkbox><br />
                                            <el-checkbox :label="item.option_d.info">{{ item.option_d.info }}
                                                <span v-if="multipleanswer.includes(item.option_d.info)"><i
                                                        class="el-icon-success" style="color:lightgreen;"></i></span>
                                                <span
                                                    v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_d.info))"><i
                                                        class="el-icon-error" style="color:red;"></i></span>
                                            </el-checkbox><br />
                                        </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item style="text-align: center">
                                        <el-button type="primary" @click="submitForm" circle
                                            style="float:right">提交</el-button>
                                    </el-form-item>
                                </div>

                            </div>

                            <!-- 提交函数  -->


                        </el-form>


                        <el-row style="padding-bottom: 20px;">
                            <el-button icon="el-icon-chat-dot-square" @click="jiqiao">技巧</el-button>
                            <el-button icon="el-icon-microphone" @click="yuyin" :disabled="yuyinbtn">语音</el-button>
                            <el-button icon="el-icon-video-play" @click="videoed">视频</el-button>

                            <el-button icon="el-icon-info" @click="uuid_info" style="float:right">唯一标识</el-button>
                        </el-row>


                        <span :class="skill == true ? showskill : noskill">{{ question[0].explains.skill.text }}</span>

                    </el-header>
                </el-container>


            </el-dialog>

            <el-dialog title="讲解视频" :visible.sync="centerDialogVisible1" width="60%" center @open="vopen" @close="vclose">
                <commonVideo :videoSource="videoSource" ref="child" />
            </el-dialog>

            <el-dialog title="修改相关信息" :visible.sync="centerDialogVisible2" width="60%" center :close-on-click-modal="false"
                :close-on-press-escape="false" :show-close="false" @close="jiqiao_close">
                <div class="demo-input-suffix">
                    问题：
                    <el-input v-model="wenti" placeholder="请输入内容" :disabled="false"></el-input>
                    <el-row class="upload">
                        <el-col :span="12">
                            选项A:
                            <el-input v-model="option_a" placeholder="请输入内容" :disabled="false"></el-input>
                        </el-col>
                        <el-col :span="12">
                            选项B:
                            <el-input v-model="option_b" placeholder="请输入内容" :disabled="false"></el-input>
                        </el-col>
                    </el-row>
                    <el-row class="upload">
                        <el-col :span="12">
                            选项C:
                            <el-input v-model="option_c" placeholder="暂无" :disabled="false"></el-input>
                        </el-col>
                        <el-col :span="12">
                            选项D:
                            <el-input v-model="option_d" placeholder="暂无" :disabled="false"></el-input>
                        </el-col>
                    </el-row>
                    技巧：
                    <el-input v-model="jiqiao_info" placeholder="请输入内容"></el-input>
                    提示关键字:
                    <el-input v-model="key_word" placeholder="提示关键字,以 + 号分割。例如: 违法+事故" :disabled="false"></el-input>
                </div>
                <div style="padding-top: 20px;padding-bottom: 20px;">
                    <el-card class="box-card">
                        <ul>
                            <li v-for="(item, i) in img_video">
                                <h4>{{ item.title }} </h4>
                                <el-link :href="item.link" target="_blank" type="success">查看<i
                                        class="el-icon-view el-icon--right"></i></el-link>
                                <el-button type="danger" size="mini" icon="el-icon-delete" style="float: right;"
                                    @click="del_file(item.title, item.uuid)"></el-button>
                            </li>
                        </ul>
                    </el-card>
                </div>

                <el-row class="upload">
                    <el-col :span="12">
                        <span>上传图片</span>
                        <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".png,.jpg,.gif"
                            :limit=1 :on-change="loadJsonFromFile" :file-list="uploadFiles" :disabled="sub_btn">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传
                                <span>jpg</span>/<span>png</span>/<span>gif</span>文件,且不超过<span>2MB</span>
                            </div>
                        </el-upload>
                        <el-progress :percentage="percentage" v-show="per_status"></el-progress>

                    </el-col>

                    <el-col :span="12">
                        <span>上传视频</span>
                        <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".mp4" :limit=1
                            :before-upload="beforeUpload1" :on-change="loadJsonFromFile1" :file-list="uploadFiles1"
                            :disabled="sub_btn">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传<span>mp4</span>文件,且不超过<span>500MB</span></div>
                        </el-upload>
                        <el-progress :percentage="percentage1" v-show="per_status1"></el-progress>
                    </el-col>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="centerDialogVisible2 = false" :disabled="sub_btn" icon="el-icon-close">关
                        闭</el-button>
                    <el-button type="primary" :disabled="sub_btn" icon="el-icon-check" :loading="sub_btn"
                        @click="put_jiqiao" style="margin-top: 30px;">确 认</el-button>
                </span>


            </el-dialog>
        </div>

    </div>
</template>


<script>
import commonVideo from '../components/commonVideo.vue'
import { Getxiaoche, searchSelect, setlocation, parcticeinfo, delproblem, search_skill, update_skill, put_skill, update_iamge } from '../api'


import COS from 'cos-js-sdk-v5';

const config = {
    // 存储桶名称
    Bucket: 'subject-picture-1257386772',
    // 地域
    Region: 'ap-nanjing'
}


export default {
    data() {
        return {
            // 判断是否有问题插图，没有则隐藏
            incrseP: 'Apirture',
            downP: 'pirture',

            currentPage4: 1,

            // 表格数据
            tableData: [],
            // 数据总条数
            total: 0,
            // 分页配置
            pageData: {
                page: 1,
                limit: 20,
            },
            // 默认车辆类型
            // car_model: {
            //     model: '小车'
            // },
            // 表单容器
            userForm: {
                select: '',
                problem: '',
                uuid: ''
            },
            options: [],
            // 表格加载数据前的loading
            loading: true,

            // 模拟做题的dialog
            centerDialogVisible: false,

            centerDialogVisible1: false,

            centerDialogVisible2: false,

            jiqiao_info: '技巧信息',
            wenti: '',
            img_video: [],
            uploadFiles: [],
            uploadFiles1: [],
            sub_btn: false,
            per_status: false,
            // 上传进度
            percentage: 0,
            percentage1: 0,
            // 是否显示进度条
            per_status: false,
            per_status1: false,
            option_a: '',
            option_b: ' ',
            option_c: '',
            option_d: '',
            key_word: '',
            // 临时存储当前题目的信息
            one_sunject: '',

            // 做题的一些定义(参考practice.vue界面)
            question: [{ "option_a": { "info": "对", "correct": true }, "option_b": { "info": "错", "correct": false }, "option_c": { "info": "", "correct": false }, "option_d": { "info": "", "correct": false }, 'explains': { 'skill': '' } }],
            ruleForm: {
                resource: ['A', '', [], []],				//每一道题的值
            },
            yuyinbtn: false,
            skill: false,
            noskill: 'noskill',
            canclick: false,
            videoSource: '',
            judgmentvalue: '',
            singlevalue: '',
            singlevalueicon: '',
            incrseP: 'Apirture',
            downP: 'pirture',
            noskill: 'noskill',
            showskill: 'showskill',
            iconstatus1: 'iconstatus1',
            iconstatus2: 'iconstatus2',
            videoSource: '',
            multiplevalue: [],
            multipleanswer: [],
        }

    },
    // 接收父组件传递的参数
    props:{
        car_model: Object 
    },
    components: {
        commonVideo
    },
    methods: {
        getExercise() {
            Getxiaoche({ params: { ...this.pageData, ...this.car_model, ...this.userForm } }).then(({ data }) => {
                this.tableData = data.data
                this.total = data.count
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageData.limit = val
            this.getExercise()

        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageData.page = val
            console.log(this.pageData)
            this.getExercise()

        },
        onSubmit() {
            console.log(this.userForm)
            this.handleChange()
            this.getExercise()
        },
        handleAdd() {
            if (this.userForm.select === '') {
                this.$message({
                    showClose: true,
                    message: '请先选择题型分类',
                    type: 'warning'
                })
            } else {
                const routeData = this.$router.resolve({
                    path: '/practice_copy',
                    query: { ...this.pageData, ...this.car_model, ...this.userForm }
                });
                window.open(routeData.href, '_blank');
            }

        },
        handleChange() {
            const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
            //console.log( checkedNode[0].data.label) ;  //获取当前点击节点的label值
            if (checkedNode.length !== 0) {
                const d = checkedNode[0].pathLabels;  //获取由label组成的数组
                const _str = d.join("/")
                this.userForm.select = _str
            } else {
                this.userForm.select = ''
            }

        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            // this.userForm.select = ''
        },
        location(val) {
            console.log(val)
            this.$prompt('请输入唯一标识', '修改题目排列顺序', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                setlocation({ data: val, uuid: value }).then(({ data }) => {
                    if (data.code == 200) {
                        this.getExercise()
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消更改'
                });
            });

        },
        dati(val) {
            this.centerDialogVisible = true
            let uuid = val.uuid
            this.search_one(uuid)
        },
        search_one(val) {
            parcticeinfo({ params: { uuid: val } }).then(({ data }) => {
                console.log(data)
                this.question = [data.data[0]]
            })
        },
        jiqiao() {
            this.skill = !this.skill
            if (this.skill === true) {
                console.log(this.dialog)
                var myAudio = new Audio();
                myAudio.preload = true;
                myAudio.controls = true;
                myAudio.src = this.question[0].skill_mp3; // 每次读数组最后一个元素
                myAudio.play();
            }

        },
        yuyin() {

            const mp3 = this.question[0].mp3
            let arr = JSON.parse(JSON.stringify(mp3))

            var myAudio = new Audio();
            myAudio.preload = true;
            myAudio.controls = true;
            myAudio.src = arr.pop(); // 每次读数组最后一个元素
            myAudio.addEventListener('ended', playEndedHandler, false);
            myAudio.play();
            console.log(arr.length)
            myAudio.loop = false; // 禁止循环，否则无法触发ended事件
            function playEndedHandler() {
                myAudio.src = arr.pop();
                myAudio.play();
                !arr.length && myAudio.removeEventListener('ended', playEndedHandler, false);//只有一个元素时解除绑定
            }

        },
        videoed() {
            if (this.question[0].video === '') {
                console.log('没有视频')
                this.$message('暂无视频讲解！');
            } else {
                this.videoSource = this.question[0].video
                this.centerDialogVisible1 = true
                
            }
        },
        uuid_info() {
            this.$notify.info({
                title: '消息',
                message: this.question[0].uuid
            })
        },
        judgment(val) {
            this.canclick = true
            console.log('点击了')
            console.log(val);
            this.judgmentvalue = val
            let answer = this.question[0].answer

            if (val == answer) {
                console.log('答对了')
                // 答对了直接跳到下一题
            } else {
                console.log('答错了')
                if (answer === '对') {
                    this.iconstatus1 = 'iconshow'
                } else {
                    this.iconstatus2 = 'iconshow'
                }
            }

        },
        single(val) {
            this.canclick = true
            console.log('点击了')
            console.log(val);

            let answer = this.question[0].answer
            console.log(answer)
            if (val == answer) {
                console.log('答对了')
                this.singlevalue = val
                // 答对了直接跳到下一题
            } else {
                console.log('答错了')
                this.singlevalue = val
                this.singlevalueicon = answer
            }

        },
        submitForm() {
            if (this.canclick) {
                this.$message({
                    showClose: true,
                    message: '已经提交过了！',
                    type: 'warning'
                })
                return
            }
            let answer = this.question[0].answer
            let arr = answer.split("; ")
            console.log(this.multiplevalue)
            this.canclick = true


            if (arr.length === this.multiplevalue.length && arr.sort().toString() === this.multiplevalue.sort().toString()) {
                console.log('答对了')
            } else if (this.multiplevalue.length == 0) {
                this.$message({
                    showClose: true,
                    message: '请选择答案后提交！',
                    type: 'warning'
                })
            } else {
                console.log('答错了, 显示正确答案')
                this.multipleanswer = arr

            }
        },
        vopen() {
            try {
                this.$refs.child.vstart();
                //播放
            } catch (error) {
                console.log('第一次打开视频异常问题');
            }

        },
        // 关闭dialog同时关闭视频
        vclose() {
            this.$refs.child.vstop();
        },
        bigvclose() {
            this.noskill = 'noskill'
            this.showskill = 'showskill'
            this.iconstatus1 = 'iconstatus1'
            this.iconstatus2 = 'iconstatus2'
            this.skill = false
            this.yuyinbtn = false
            this.centerDialogVisible = false
            this.judgmentvalue = ''
            this.canclick = false
            this.singlevalue = ''
            this.singlevalueicon = ''
            this.multiplevalue = []
            this.multipleanswer = []
        },
        delsubject(val) {
            let uuid = val.uuid
            console.log(uuid)
            this.$confirm('此操作将永久删除该题目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delproblem({ params: { uuid: uuid } }).then(({ data }) => {
                    if (data.code == 200) {
                        this.getExercise()
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleClick(val) {
            this.one_sunject = val
            this.centerDialogVisible2 = true
            search_skill({ params: { uuid: val.uuid } }).then(({ data }) => {
                this.jiqiao_info = data.data.jiqiao;
                this.img_video = data.data.img_video;
                this.wenti = data.data.problem;
                this.option_a = data.data.option_a;
                this.option_b = data.data.option_b;
                this.option_c = data.data.option_c;
                this.option_d = data.data.option_d;
                this.key_word = data.data.key_word;

            })
        },
        del_file(file_type, code) {
            console.log(file_type, code)
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                update_skill({ file_type: file_type, uuid_code: code }).then(({ data }) => {
                    if (data.code == 200) {
                        this.handleClick(this.one_sunject)
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        loadJsonFromFile(file, fileList) {
            console.log(file, fileList)
            this.uploadFiles = fileList
        },
        loadJsonFromFile1(file, fileList) {
            this.uploadFiles1 = fileList
        },
        beforeUpload(file_list) {
            if (file_list.length == 0) {
                return true
            }
            console.log(file_list)
            let file = file_list[0]
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

            const whiteList = ["jpg", "png", "gif"];

            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 jpg, png, gif 格式');
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isLt2M) {
                this.$message.error('上传的图片大小不能超过 5MB');
                return false;
            }
            return true
        },
        beforeUpload1(file_list) {
            if (file_list.length == 0) {
                return true
            }
            let file = file_list[0]
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

            const whiteList = ["mp4"];

            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 mp4 格式');
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 500;

            if (!isLt2M) {
                this.$message.error('上传的视频大小不能超过 500MB');
                return false;
            }
            return true
        },
        random_str(){
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            let maxPos = chars.length;
            var code = '';
            for (let i = 0; i < 6; i++) {
                code += chars.charAt(Math.floor(Math.random() * maxPos));
            }

            return code

        },
        put_jiqiao() {
            console.log(this.jiqiao_info)
            this.sub_btn = true;
            let res = this.beforeUpload(this.uploadFiles)
            let res1 = this.beforeUpload1(this.uploadFiles1)
            let that = this
            // 校验附件是否符合要求
            if (res == true && res1 == true) {

                put_skill({ jiqiao: this.jiqiao_info, 
                            uuid_code: this.one_sunject.uuid, 
                            image: this.uploadFiles.length, 
                            video: this.uploadFiles1.length, 
                            bucket: config.Bucket,
                            wenti: this.wenti,
                            option_a: this.option_a,
                            option_b: this.option_b,
                            option_c: this.option_c,
                            option_d: this.option_d,
                            key_word: this.key_word
                        }).then(({ data }) => {
                    if (data.code == 200) {
                        this.sub_btn = false;
                        let subject_info = data
                        if (this.uploadFiles.length == 0 && this.uploadFiles1.length == 0) {
                            this.$message.success(data.msg)
                        }
                        var cos = new COS({
                            // getAuthorization 必选参数
                            getAuthorization: function (options, callback) {
                                // 异步获取临时密钥
                                // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
                                // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
                                // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
                                callback({
                                    TmpSecretId: data.cos_data.credentials.tmpSecretId,
                                    TmpSecretKey: data.cos_data.credentials.tmpSecretKey,
                                    SecurityToken: data.cos_data.credentials.sessionToken,
                                    // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                                    StartTime: data.cos_data.startTime, // 时间戳，单位秒，如：1580000000
                                    ExpiredTime: data.cos_data.expiredTime, // 时间戳，单位秒，如：1580000000
                                });
                            }
                        });
                        console.log('开始上传')
                        console.log(this.uploadFiles)
                        // 开始上传文件
                        if (this.uploadFiles.length == 1) {
                            // 按钮不可点击
                            that.sub_btn = true
                            // 显示进度条
                            that.per_status = true

                            const photo_name = this.random_str() + '.jpg'
                            cos.uploadFile({
                                Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                                Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                                Key: data.sub_code + '/' + photo_name,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                Body: this.uploadFiles[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                                onTaskReady: function (taskId) {                   /* 非必须 */
                                    console.log(taskId);
                                },
                                onProgress: function (progressData) {           /* 非必须 */
                                    console.log(JSON.stringify(progressData));
                                    const percent = parseInt(progressData.percent * 10000) / 100;
                                    const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                                    console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                                    that.percentage = percent
                                    p = percent
                                },
                                onFileFinish: function (err, data, options) {   /* 非必须 */
                                    if (that.uploadFiles1.length == 0) {
                                        that.sub_btn = false
                                    }
                                    console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                                    if (!err) {
                                        console.log('图片上传成功')
                                        update_iamge({ data: { file_type: 'image', code: subject_info.sub_code, phone_name: photo_name } }).then(({ data }) => {
                                            that.$message.success(data.msg)
                                        })
                                    }
                                },
                            }, function (err, data) {

                                if (that.uploadFiles1.length == 0) {
                                    that.sub_btn = false
                                }
                                console.log(err || data);
                            });

                        }

                        if (this.uploadFiles1.length == 1) {
                            this.sub_btn = true
                            that.per_status1 = true
                            cos.uploadFile({
                                Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                                Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                                Key: data.sub_code + '/' + data.sub_code + '.mp4',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                Body: this.uploadFiles1[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                                onTaskReady: function (taskId) {                   /* 非必须 */
                                    console.log(taskId);
                                },
                                onProgress: function (progressData) {           /* 非必须 */
                                    console.log(JSON.stringify(progressData));
                                    const percent = parseInt(progressData.percent * 10000) / 100;
                                    const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                                    console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                                    that.percentage1 = percent
                                },
                                onFileFinish: function (err, data, options) {   /* 非必须 */
                                    that.sub_btn = false
                                    console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                                    if (!err) {
                                        console.log('图片上传成功')
                                        update_iamge({ data: { file_type: 'video', code: subject_info.sub_code } }).then(({ data }) => {
                                            that.$message.success(data.msg)
                                        })
                                    }
                                },
                            }, function (err, data) {
                                that.sub_btn = false
                                console.log(err || data);
                            });

                        }
                    } else {

                    }

                })
            }

        },
        jiqiao_close() {
            this.uploadFiles = []
            this.uploadFiles1 = []
            this.per_status = false
            this.per_status1 = false
        }

    },
    mounted() {

        Getxiaoche({ params: { ...this.pageData, ...this.car_model, ...this.userForm } }).then(({ data }) => {
            this.tableData = data.data
            this.total = data.count
            this.loading = false

        }),
        searchSelect({ params: this.car_model }).then(({ data }) => {
            this.options = data.data
        })


    }

}

</script>

<style lang="less" scoped>
.demo-table-expand {
    font-size: 0;
    margin-left: 180px;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}

.pirture {
    display: none;
}

.Bpirture {
    display: none;
}

.correct {
    color: red;
}


.manage {
    height: 98%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .el-input {
            margin-left: 20px;

        }

        .el-button--primary {
            margin-left: 20px;
        }

        .el-cascader {
            width: 250px;
        }

        .el-form--inline {
            padding-top: 20px;
        }
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 62px);

        .pager {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }


}

.pirture {
    display: none;
}

.Apirture {
    margin-left: 30%;
    margin-top: 20px;
    // width: 180px;
    height: 260px;

}

.noskill {
    display: none;
}

.showskill {
    font-size: 20px;
    color: red;

}

.iconstatus1 {
    display: none;
}

.iconstatus2 {
    display: none;
}

.iconshow {
    display: inline;
}

.demo-ruleForm {

    // 处理选项款不靠左贴边问题
    /deep/.el-form-item__content {
        margin-left: 0px !important;
    }

    .VSTD_box_item_select {
        // 修改选项框大小,字体颜色,上下间隙
        zoom: 150%;

        .el-radio {
            color: #000;
            padding-top: 20px;
        }
    }
}

.upload {
    span {
        color: red;
    }
}

.color_set {
    font-size: 25px;
  /deep/ span {
     color: red
  }
}
</style>
