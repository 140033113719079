<template>
    <div class="manage">
        <el-dialog title="新增学员信息" :visible.sync="dialogVisible" width="60%" @close="handleClose('ruleForm')">
            <!-- 用户的表单信息 -->
            <el-form ref="ruleForm" :rules="rules" :inline="true" :model="ruleForm" label-width="80px">
                <el-row>
                    <el-col :span="10">
                        <!-- <el-input placeholder="手机号" v-model="ruleForm.phone" maxlength="11" minlength="11">
                        <template slot="prepend">手机号</template>
                        <el-button slot="append">发送验证码</el-button>
                    </el-input> -->
                        <el-form-item label="手机号" prop="phone">
                            <el-input placeholder="手机号码" v-model="ruleForm.phone" clearable maxlength=11 minlength=11 
                                oninput="value=value.replace(/[^\d]/g,'')">
                                <el-button slot="append" @click="send_sms" :disabled="isDisabled">{{ content
                                }}</el-button></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="验证码" prop="ver_code">
                            <el-input placeholder="短信验证码" v-model="ruleForm.ver_code" clearable maxlength=6 ></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                &nbsp;
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="姓名" prop="user_name">
                            <el-input placeholder="姓名" v-model="ruleForm.user_name" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="身份证" prop="id_card">
                            <el-input placeholder="身份证号码" v-model="ruleForm.id_card" clearable maxlength="18"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>



                &nbsp;
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="年龄" prop="age">
                            <el-input placeholder="年龄" v-model="ruleForm.age" clearable
                                oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="ruleForm.sex">
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>


                    </el-col>

                </el-row>

                &nbsp;
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="文化程度" prop="wenhua">
                            <el-select v-model="ruleForm.wenhua" placeholder="请选择文化程度" @change="">
                                <el-option label="文盲" value="文盲"></el-option>
                                <el-option label="小学" value="小学"></el-option>
                                <el-option label="中学" value="中学"></el-option>
                                <el-option label="大学" value="大学"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="驾校" prop="jiaxiao">
                            <el-input placeholder="驾校" v-model="ruleForm.jiaxiao" clearable></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                &nbsp;
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="推荐人" prop="tuijian">
                            <el-input placeholder="推荐人" v-model="ruleForm.tuijian" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="老师" prop="teacher">
                            <el-input placeholder="老师" v-model="ruleForm.teacher" clearable></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                &nbsp;
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="学习科目" prop="activation">
                            <el-cascader :options="options" :props="props" collapse-tags clearable
                                v-model="ruleForm.activation">
                            </el-cascader>

                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="费用" prop="expense">
                            <el-input placeholder="费用" v-model="ruleForm.expense"  type="number" clearable></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item label="备注信息" prop="remark">
                            <el-input placeholder="备注信息" v-model="ruleForm.remark" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="学时" prop="period1">
                            <el-input placeholder="学时(小时单位)" v-model="ruleForm.period1"  type="number" clearable
                                oninput="value=value.replace(/[^\d]/g,'')" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>

                <el-row>

                    <el-col :span="10">
                        <el-form-item label="返点(元)" prop="amount">
                            <el-input placeholder="人民币(元)" v-model="ruleForm.amount" type="number" clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>

                </el-row>

                

            </el-form>

            <span slot="footer" style="text-align: center;display:block;">
                <el-button @click="cancel('ruleForm')">重 置</el-button>
                <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="编辑用户信息" :visible.sync="edit_user" width="50%">
            <!-- 用户的表单信息 -->
            <el-form ref="form" :model="editForm" label-width="80px">

                <el-form-item label="学习科目" prop="activation" v-show="user_type != '老师'">
                    <el-cascader :options="options" :props="props" collapse-tags clearable v-model="editForm.activation">
                    </el-cascader>

                </el-form-item>


                <el-form-item label="费用" prop="cost" v-show="user_type != '老师'">
                    <el-input placeholder="人民币(元)" v-model="editForm.cost" clearable></el-input>
                </el-form-item>

                <el-form-item label="返点(元)" prop="amount" v-show="user_type != '老师'">
                    <el-input placeholder="人民币(元)" v-model="editForm.amount" clearable type="number"></el-input>
                </el-form-item>

                <el-form-item label="学时(小时)" prop="period" v-show="user_type = '管理员'">
                    <el-input placeholder="学时(小时单位)" v-model="editForm.period" clearable></el-input>
                </el-form-item>

                <el-form-item label="姓名" prop="nickname" v-show="user_type != '老师'">
                    <el-input placeholder="姓名" v-model="editForm.nickname" clearable></el-input>
                </el-form-item>

                <el-form-item label="备注信息" prop="remark" v-show="user_type != '老师'">
                    <el-input placeholder="备注信息" v-model="editForm.remark" clearable></el-input>
                </el-form-item>

                <el-form-item label="退款(元)" prop="amount" v-show="user_type != '老师'">
                    <el-input placeholder="人民币(元)" v-model="editForm.refund" clearable type="number"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" style="text-align: center;display:block;">
                <el-button @click="edit_user = false">取 消</el-button>
                <el-button type="primary" @click="up_submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="学员详细信息" :visible.sync="show_detail" width="70%" :close-on-click-modal="false" @close="res_set">

            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="基本资料" name="first" >
                    <el-descriptions title="" style="font-size: 20px; color: #000;">
                        <el-descriptions-item label="用户名">{{ user_detail.nickname }}</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{ user_detail.phone }}</el-descriptions-item>
                        <el-descriptions-item label="身份证">{{ user_detail.identity_card }}</el-descriptions-item>
                        <el-descriptions-item label="年龄">{{ user_detail.age }}</el-descriptions-item>
                        <el-descriptions-item label="性别">{{ user_detail.sex }}</el-descriptions-item>
                        <el-descriptions-item label="文化程度">{{ user_detail.degree }}</el-descriptions-item>
                        <el-descriptions-item label="驾校">{{ user_detail.driving_school }}</el-descriptions-item>
                        <el-descriptions-item label="推荐人">{{ user_detail.referrer }}</el-descriptions-item>
                        <el-descriptions-item label="老师">{{ user_detail.teacher }}</el-descriptions-item>
                        <el-descriptions-item label="总费用">{{ user_detail.cost }}</el-descriptions-item>
                        <el-descriptions-item label="总返点">{{ user_detail.brokerage }}</el-descriptions-item>
                        <el-descriptions-item label="总退款">{{ user_detail.refund }}</el-descriptions-item>
                        <el-descriptions-item label="剩余学时">{{ user_detail.period }}</el-descriptions-item>
                        <el-descriptions-item label="总学时">{{ user_detail.sum_period }}</el-descriptions-item>
                        <el-descriptions-item label="备注">{{ user_detail.remark }}</el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>
                <el-tab-pane label="模拟考试成绩列表" name="second">
                    <el-table :data="exam_results" style="width: 100%">
                        <el-table-column prop="user_name" label="学员" width="180">
                        </el-table-column>
                        <el-table-column prop="car" label="车型" width="180">
                        </el-table-column>
                        <el-table-column prop="subjects" label="科目">
                        </el-table-column>
                        <el-table-column prop="score" label="成绩">
                        </el-table-column>
                        <el-table-column prop="on_time" label="时间">
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="预约考试" name="third">
                    <el-form :inline="true" :model="book_exam" class="demo-form-inline">
                    <el-form-item label="科目">
                        <el-cascader :options="options"  clearable v-model="book_exam.activation"> </el-cascader>
                    </el-form-item>
                    <el-form-item label="考试时间">
                        <el-date-picker
                        v-model="book_exam.exam_time"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注信息">
                        <el-input v-model="book_exam.remark" placeholder="备注内容"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="exam_sub">添加</el-button>
                    </el-form-item>
                    </el-form>

                    <el-table :data="reservation_exam" style="width: 100%">
                        <el-table-column prop="user_name" label="学员" width="100">
                        </el-table-column>
                        <el-table-column prop="car" label="车型" width="70">
                        </el-table-column>
                        <el-table-column prop="subjects" label="科目" width="70">
                        </el-table-column>
                        <el-table-column prop="log_time" label="预约时间">
                        </el-table-column>
                        <el-table-column prop="on_time" label="考试时间">
                        </el-table-column>
                        <el-table-column prop="complete_time" label="完成时间">
                        </el-table-column>
                        <el-table-column prop="complete_status" label="状态">
                        </el-table-column>
                        <el-table-column prop="remark" label="备注">
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-button @click="exam_pass(scope.row)" type="text" size="small">通过</el-button>
                                <el-button @click="exam_fail(scope.row)" type="text" size="small">挂科</el-button>
                                <el-button @click="exam_del(scope.row)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    
                </el-tab-pane>
                <!-- <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane> -->
            </el-tabs>

        </el-dialog>

        <el-dialog title="学科管理" :visible.sync="subject_detail" width="40%">

            <el-table :data="subject_Data" style="width: 100%">
                <el-table-column label="车型" width="180">
                    <template slot-scope="scope">
                        <i class="el-icon-s-order"></i>
                        <span style="margin-left: 10px">{{ scope.row.type }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="科目" width="180">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.status === 'T' ? 'success' : 'danger'"
                            disable-transitions>{{ scope.row.subject }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="手机" width="180">
                    <template slot-scope="scope">
                        <i class="el-icon-phone"></i>
                        <span style="margin-left: 10px">{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" :type="scope.row.status === 'T' ? 'danger' : 'primary'"
                            @click="sub_close(scope.row)">

                            <span v-if="scope.row.status === 'T'">关闭</span>
                            <span v-if="scope.row.status === 'F'">开始</span>

                        </el-button>


                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <el-drawer title="用户信息修改记录" :visible.sync="log_data" :with-header="false" direction="ltr" :wrapperClosable=true>
            <div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
            </div>
            <div class="block" style="font-size: 25px;">
                <div class="radio">
                    排序：
                    <el-radio-group v-model="reverse">
                        <el-radio :label="true">倒序</el-radio>
                        <el-radio :label="false">正序</el-radio>
                    </el-radio-group>
                </div>

                <div>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    &nbsp;
                </div>

                <el-timeline :reverse="reverse">
                    <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp"
                        size="large">
                        {{ activity.content }}
                    </el-timeline-item>
                </el-timeline>
            </div>
        </el-drawer>

        <div class="manage-header">

            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm">
                <el-form-item>
                    <el-input placeholder="手机号码" v-model="userForm.phone" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="姓名" v-model="userForm.user_name" clearable></el-input>
                </el-form-item>
                <el-form-item v-show="identity != '老师'">
                    <el-select v-model="userForm.select_value" filterable placeholder="下级选择"  clearable>
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>

            <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button>
        </div>
        <div class="common-tabel">
            <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading"
                element-loading-text="拼命加载中" :row-style="{ height: '10px' }" highlight-current-row>
                <el-table-column type="index" label="序号">
                    <template slot-scope="scope">
                        {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="user_name" label="用户名" width="100">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="120">
                </el-table-column>
                <el-table-column prop="sex" label="性别" width="50">
                </el-table-column>
                <el-table-column prop="create_user" label="创建人" width="140">
                    <template slot-scope="scope">
                        <el-tag type="success" disable-transitions>{{ scope.row.create_user }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="tag" label="状态" width="140">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.status === '正常' ? 'primary' : 'danger'"
                    disable-transitions>{{scope.row.status}}</el-tag>
                </template>
                </el-table-column>
                <el-table-column prop="xiaoche" label="小车" width="220">
                </el-table-column>
                <el-table-column prop="motuo" label="摩托车" width="220">
                </el-table-column>
                <el-table-column prop="keche" label="客车" width="220">
                </el-table-column>
                <el-table-column prop="huoche" label="货车" width="220">
                </el-table-column>
                <el-table-column prop="sanli" label="三力" width="100">
                </el-table-column>
                <el-table-column prop="cost" label="学费" width="100">
                </el-table-column>
                <el-table-column prop="period" label="学时(时)" width="100">
                </el-table-column>
                <el-table-column prop="addr" label="编辑" width="480" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="edit(scope.row)"><i
                                class="el-icon-edit"></i>编辑</el-button>
                        <el-button type="info" size="mini" @click="subjects(scope.row)" v-show="scope.row.user_type != '老师'"><i
                                class="el-icon-set-up"></i>学科</el-button>
                        <el-button type="success" size="mini" @click="show(scope.row)"><i
                                class="el-icon-user-solid"></i>资料</el-button>
                        <el-button type="warning" size="mini" @click="log_info(scope.row)" v-show="scope.row.user_type != '老师'"><i
                                class="el-icon-tickets"></i>记录</el-button>
                        <el-button type="" size="mini" @click="handleRemove(scope.row)"><i
                                class="el-icon-circle-close"></i>销户</el-button>
                        <el-button type="danger" size="mini" @click="handleDelete(scope.row)" v-show="scope.row.user_type == '管理员'"><i
                                class="el-icon-delete"></i>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>


    </div>
</template>
<script>

import { app_user, send_sms_api, app_user_exam, add_app_user, one_app_user, update_app_user,
         del_app_user, app_user_change, app_subjects_detail, app_subjects_set, add_exam_log, search_exam_log, 
         set_exam_log, admin_info, teacher_name, set_app_user_del} from '../api'


export default {
    data() {
        return {
            user_type: '',
            dialogVisible: false,
            edit_user: false,
            editForm: {
                openid: ''
            },
            book_exam: {},
            activeName: 'first',
            loading: true,
            show_detail: false,
            user_detail: {},
            currentPage1: 1,
            // 当前内容的
            ruleForm: {
                phone: '',
                ver_code: '',
                user_name: '',
                id_card: '',
                age: '',
                sex: '',
                wenhua: '',
                jiaxiao: '',
                tuijian: '',
                teacher: '',
                activation: '',
                expense: '',
                remark: '',
                period1: 120,
                amount: ''
            },
            exam_results: [],
            sub_btn: false,
            isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
            content: '发送', // 发送验证码按钮的初始显示文字
            timer: null,
            count: '',
            rules: {
                phone: [
                    { required: true, message: '请输入手机号码!' }
                ],
                ver_code: [
                    { required: true, message: '请输入手机获取到的短信验证码!' }
                ],
                user_name: [
                    { required: true, message: '请输入姓名!' }
                ],
                activation: [
                    { required: true, message: '请选择学习科目!' }
                ],
                expense: [
                    { required: true, message: '请输入费用金额!' }
                ],
                period1: [
                    { required: true, message: '请输入学时(小时单位)!', trigger: ['change', 'blur'] }
                ]
            },
            options: [{
                value: 1,
                label: '小车',
                children: [{
                    value: 2,
                    label: '科目一',
                }, {
                    value: 5,
                    label: '科目四',
                }, {
                    value: 6,
                    label: '满分',
                }, {
                    value: 7,
                    label: '注销恢复',
                }]
            }, {
                value: 8,
                label: '摩托车',
                children: [{
                    value: 9,
                    label: '科目一',
                }, {
                    value: 10,
                    label: '科目四',
                }, {
                    value: 11,
                    label: '满分',
                }, {
                    value: 12,
                    label: '注销恢复',
                }]
            }, {
                value: 13,
                label: '客车',
                children: [{
                    value: 14,
                    label: '科目一',
                }, {
                    value: 15,
                    label: '科目四',
                }, {
                    value: 16,
                    label: '满分',
                }, {
                    value: 17,
                    label: '注销恢复',
                }]
            }, {
                value: 18,
                label: '货车',
                children: [{
                    value: 19,
                    label: '科目一',
                }, {
                    value: 20,
                    label: '科目四',
                }, {
                    value: 21,
                    label: '满分',
                }, {
                    value: 22,
                    label: '注销恢复',
                }]
            }, {
                value: 23,
                label: '三力',
                children: [{
                    value: 24,
                    label: '三力测试',
                }]
            }],
            props: { multiple: true },
            tableData: [],
            modalType: 0, // 0表示新增的弹窗, 1表示编辑
            total: 0, //当前的总条数
            pageData: {
                page: 1,
                limit: 10
            },
            userForm: {
                phone: '',
                user_name: '',
                select_value: ''
            },
            centerDialogVisible2: false,
            centerDialogVisible1: false,
            set_car_model: '',
            set_subjects: '',
            set_class_name: '',
            log_data: false,
            reverse: true,
            activities: [],
            subject_detail: false,
            subject_Data: [],
            reservation_exam: [],
            identity: '',
            options1: [
                // {
                //     value: '选项1',
                //     label: '黄金糕'
                // }
            ]
        }
    },
    methods: {
        // 提交用户表单
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    console.log(this.ruleForm)
                    add_app_user({ data: this.ruleForm }).then(({ data }) => {
                        if (data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: data.msg
                            })
                            this.getList()

                            // 清空表单的数据
                            this.$refs[formName].resetFields();
                            // 关闭弹窗
                            this.dialogVisible = false
                        } else {
                            this.$message({
                                type: 'error',
                                message: data.msg
                            })
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        res_set(){
            this.book_exam = {}
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },

        up_submit() {
            update_app_user({ data: this.editForm }).then(({ data }) => {
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: data.msg
                    })
                    this.getList()

                    // 关闭弹窗
                    this.edit_user = false
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            })
        },

        // 弹窗关闭的时候
        handleClose(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
        },
        cancel(formName) {
            this.$refs[formName].resetFields();
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该用户信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                del_app_user({ params: row }).then(({ data }) => {
                    if (data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }

                    // 重新获取列表的接口
                    this.getList()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleRemove(row) {
            this.$confirm('此操作表示用户已完结学习任务，确认后将不可在登录软件学习, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                set_app_user_del({ params: row }).then(({ data }) => {
                    if (data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }

                    // 重新获取列表的接口
                    this.getList()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleAdd() {
            this.modalType = 0
            this.dialogVisible = true
        },
        // 获取列表的数据
        getList() {
            // 获取的列表的数据
            app_user({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                console.log(data)
                this.tableData = data.data

                this.total = data.count || 0
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageData.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageData.page = val
            console.log(this.pageData)
            this.getList()

        },
        // 选择页码的回调函数
        handlePage(val) {
            // console.log(val, 'val')
            this.pageData.page = val
            this.getList()
        },
        // 列表的查询
        onSubmit() {
            // this.pageData.page = 1
            this.getList()
        },
        getExercise() {
            app_user({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                this.tableData = data.data
                this.total = data.count
                this.loading = false
            })
        },
        send_sms() {
            this.isDisabled = true;
            console.log('发送短信')
            const phone = this.ruleForm.phone
            if (!this.isMobile(phone)) {
                this.$message.error('请输入正确的手机号码格式！')
                this.isDisabled = false;
                return;
            }
            send_sms_api({ params: { ...this.ruleForm } }).then(({ data }) => {
                if (data.code == 200) {

                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                                console.log(this.count)
                                this.content = this.count + '秒后重试 '
                            } else {
                                this.isDisabled = false;
                                clearInterval(this.timer);
                                this.timer = null;
                                this.content = '发送'
                            }
                        }, 1000)
                    }
                } else {
                    this.$message.error(data.msg)
                    this.isDisabled = false;
                }

            })

        },
        isMobile(val) {
            return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(val);
        },
        edit(val) {
            console.log(val)
            this.editForm = {}
            one_app_user({ params: { openid: val.openid } }).then(({ data }) => {
                if (data.code == 200) {
                    this.editForm.openid = data.data.openid
                    this.edit_user = true
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
        search_exam(openid){
            search_exam_log({ params: { openid: openid } }).then(({ data }) => {
                if (data.code == 200) {
                    this.reservation_exam = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
        show(val) {

            one_app_user({ params: { openid: val.openid } }).then(({ data }) => {
                if (data.code == 200) {
                    this.user_detail = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })

            app_user_exam({ params: { openid: val.openid } }).then(({ data }) => {
                if (data.code == 200) {
                    this.exam_results = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
            
            this.search_exam(val.openid)

            this.book_exam.openid = val.openid

            this.show_detail = true


        },
        log_info(val) {

            app_user_change({ params: { openid: val.openid } }).then(({ data }) => {
                if (data.code == 200) {
                    this.activities = data.data
                    this.log_data = true
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
        subjects(val) {
            this.subject_detail = true
            this.get_app_subjects(val)
        },
        get_app_subjects(val) {
            app_subjects_detail({ params: { openid: val.openid } }).then(({ data }) => {
                if (data.code == 200) {
                    console.log(data)
                    this.subject_Data = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
        sub_close(val) {
            console.log(val)
            app_subjects_set({ params: val }).then(({ data }) => {
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: data.msg
                    });
                    const _d = { openid: val.phone }
                    this.get_app_subjects(_d)
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
        exam_sub(){
            console.log(this.book_exam)
            add_exam_log({ data: this.book_exam }).then(({ data }) => {
                        if (data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: data.msg
                            })
                            this.search_exam(this.book_exam.openid)
                        } else {
                            this.$message({
                                type: 'error',
                                message: data.msg
                            })
                        }
                    })
        },
        exam_fail(val){
            console.log(val)
            console.log('挂科')
            set_exam_log({ params: { _id: val.id, set_type: 'fail' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam(this.book_exam.openid)
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },
        exam_pass(val){
            console.log(val)
            console.log('通过')
            set_exam_log({ params: { _id: val.id, set_type: 'pass' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam(this.book_exam.openid)
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },
        exam_del(val){
            console.log(val)
            console.log('删除')
            set_exam_log({ params: { _id: val.id, set_type: 'del' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam(this.book_exam.openid)
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },

    },
    mounted() {
        app_user({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
            this.total = data.count
            this.loading = false
            this.user_type = data.user_type
        }),
        admin_info().then(({ data }) =>{
            //this.user_info = data.data
            this.identity = data.identity
        })

        teacher_name().then(({ data }) =>{
            //this.user_info = data.data
            // this.identity = data.identity
            this.options1 = data.data
        })

    }
}
</script>
<style lang="less" scoped>
.manage {
    height: 90%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 1px);

        .pager {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }
}
</style>