import Cookie from "js-cookie"

export default{
    state: {
        // 用于控制菜单的展开和收起
        isCollapse: false,
        // 收起菜单的cion图标
        set_icon: 'el-icon-arrow-left',
        // 面包屑的数据
        tabsList: [

            {
                path: '/',
                name: 'home',
                label: '首页',
                icon: 's-home',
                url: 'Home/Home'
            },
        ], 
        menu: [],
    },
    mutations: {
        // 修改菜单展开收起的方法
        collapseMenu(state){
            state.isCollapse = !state.isCollapse
        },
        // 设置菜单图标方向
        getMentdirection(state){
            if (state.set_icon == 'el-icon-arrow-left'){
                state.set_icon = 'el-icon-arrow-right'
            } else {
                state.set_icon = 'el-icon-arrow-left'
            }
        },
        //更新面包屑数据 state 是当前对象 val 是调用传过来的值
        selectMenu(state, val){
            const index = state.tabsList.findIndex(item => item.name === val.name)
            if (index === -1){
                state.tabsList.push(val)
            }
            
        },
        // 设置menu的数据
        setMenu(state, val){
            state.menu = val
            // Cookie.set('menu', JSON.stringify(val))
        },
        // 动态注册路由,防止url强制跳转
        addMenu(state, router){
            // 判断缓存中是否包含数据
            if (!localStorage.getItem("menu")) return
            const menu = JSON.parse(localStorage.getItem("menu"))
            state.menu = menu
            const menuArray = []
            menu.forEach(item => {
                if (item.children){

                    item.children = item.children.map(item => {
                        item.component = () => import(`../views/${item.url}`)
                        return item
                    })
                    menuArray.push(...item.children)

                } else {
                    item.component = () => import(`../views/${item.url}`)
                    menuArray.push(item)
                }
            });
            // console.log(menuArray, 'menuArray')

            // 路由的动态添加
            menuArray.forEach(item => {
                router.addRoute('main', item)
            })
        }
    }
}