<template>
    <div>
        <vue-core-video-player :src="videoSource" :cover="cover" :title="title" ref="videoPlayer" preload="nona" :loop="loop"/>
    </div>
</template>


<script>


export default {
    data() {
        return {
            // videoSource: [{
            //     src: 'https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/video-ld.mp4',
            //     // 分辨率参数提供设置不同的分辨率播放功能
            //     // resolution: 360,
            // }],
            // videoSource: Array,
            cover: "https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E8%A7%86%E9%A2%91%E5%B0%81%E9%9D%A2.jpg",
            title: "易点通驾考理论",
            loop:true
        }
    },
    // 接收父组件传递的参数
    props:{
        videoSource: String
    },
    methods:{
        vstop(){
            let myPlayer = this.$refs.videoPlayer;
            //停止播放
            console.log(myPlayer)
            // this.loop = false
            myPlayer.pause()
        },
        vstart(){
            let myPlayer = this.$refs.videoPlayer;
            //停止播放
            myPlayer.src=this.videoSource;
            console.log(myPlayer)
            myPlayer.play()
        }
        
    }
}

</script>