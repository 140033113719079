<template>
    <el-container>
        <el-header>
            <el-skeleton :rows="12" animated  :loading="logined"/>
        </el-header>
    </el-container>
</template>

<script>
import { login } from '../api'

import Cookie from 'js-cookie'

export default {
    name: "Login",
    data() {
        return {
            captchaUrl: "",
            loginForm: {
                username: "",
                password: "",
                code: '',
                checked: false,
            },
            logined: true

        }
    },
    methods: {
    },
    mounted() {

        // 清楚cookie中的token
        Cookie.remove('admin_token')

        // 清楚cookie中的menu权限菜单信息
        Cookie.remove('menu')

        let account = this.$route.query.account
        let key = this.$route.query.key
        if (account !== null && account !== undefined && key !== null && key !== undefined){
            console.log(account, key)
            this.loginForm.username = account;
            this.loginForm.key = key;
            this.loginForm.checked = true;
        
            login({ data: this.loginForm }).then(({ data }) => {
            if (data.code == 200){
                this.logined = false
                console.log('access_token')
                Cookie.set('admin_token', data.access_token)
                // Cookie.set('admin_token', data.access_token, { sameSite: null, secure: false })
                this.$message.success('登录成功!')
                
                // 获取菜单的数据, 存入store中
                localStorage.setItem("menu",  JSON.stringify(data.menu))
                this.$store.commit('setMenu', data.menu)
                this.$store.commit('addMenu', this.$router)
                this.$router.push('/home')

            } else if(data.code == 500) {
                this.$message.error('账号或密码错误')
            }
            }).catch(err => {
                // this.$message.error('账号或密码错误')
            })
        }
        

    },
    created(){
        // 清楚cookie中的token
        // Cookie.remove('admin_token')

        // 清楚cookie中的menu权限菜单信息
        // Cookie.remove('menu')
    }
};
</script>

<style lang="less" >
.loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 180px auto;
    width: 350px;
    padding: 15px 35px 15px 35px;
    background: aliceblue;
    border: 1px solid blueviolet;
    box-shadow: 0 0 25px #f885ff;
}

.loginTitle {
    margin: 0px auto 48px auto;
    text-align: center;
    font-size: 40px;
}

.loginRemember {
    text-align: left;
    margin: 0px 0px 15px 0px;
}

.login {
    background-image: url("https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E5%88%9B%E5%A7%8B%E4%BA%BA%E4%BB%8B%E7%BB%8D.jpg");
    background-size: 100%;
    height: 100%;
}
</style>

