<template>
    <div class="manage">
        <el-dialog title="新增管理员信息" :visible.sync="dialogVisible" width="60%" @close="handleClose('ruleForm')">
            <!-- 用户的表单信息 -->
            <el-form ref="ruleForm" :rules="rules" :inline="false" :model="ruleForm" label-width="80px">

                <el-form-item label="手机号" prop="phone">
                    <el-input placeholder="手机号码" v-model="ruleForm.phone" clearable maxlength=11 minlength=11
                        oninput="value=value.replace(/[^\d]/g,'')">
                        <el-button slot="append" @click="send_sms" :disabled="isDisabled">{{ content
                        }}</el-button></el-input>
                </el-form-item>

                <el-form-item label="验证码" prop="ver_code">
                    <el-input placeholder="短信验证码" v-model="ruleForm.ver_code" clearable maxlength=6></el-input>
                </el-form-item>

                <el-form-item label="登录密码" prop="password">
                    <el-input placeholder="登录密码" v-model="ruleForm.password" disabled></el-input>
                </el-form-item>

                <el-form-item label="姓名" prop="user_name">
                    <el-input placeholder="姓名" v-model="ruleForm.user_name" clearable></el-input>
                </el-form-item>

                <el-form-item label="权限选择" prop="user_type">
                    <el-select v-model="ruleForm.user_type" clearable placeholder="请选择" style="display: block;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="身份证" prop="id_card">
                    <el-input placeholder="身份证号码" v-model="ruleForm.id_card" clearable maxlength="18"></el-input>
                </el-form-item>



                <el-row>
                    <el-col :span="10">

                        <el-form-item label="地址" prop="selectedOptions">
                            <el-cascader size="large" :options="pcaTextArr" v-model="ruleForm.selectedOptions">
                            </el-cascader>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="详细地址" prop="address">
                            <el-input placeholder="详细地址信息" v-model="ruleForm.address" clearable></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-form-item label="备注信息" prop="remark">
                    <el-input placeholder="备注信息" v-model="ruleForm.remark" clearable></el-input>
                </el-form-item>


            </el-form>

            <span slot="footer" style="text-align: center;display:block;">
                <el-button @click="cancel('ruleForm')">重 置</el-button>
                <el-button type="primary" @click="submit('ruleForm')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="修改用户信息" :visible.sync="edit_user" width="50%" @close="handleClose_edit('form')">
            <!-- 用户的表单信息 -->
            <el-form ref="form" :model="editForm" label-width="80px">

                <el-form-item label="权限选择" prop="user_type">
                    <el-select v-model="editForm.user_type" clearable placeholder="请选择" style="display: block;">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="身份证" prop="id_card">
                    <el-input placeholder="身份证号码" v-model="editForm.id_card" clearable maxlength="18"></el-input>
                </el-form-item>

                <el-form-item label="登录密码" prop="password">
                    <el-input placeholder="登录密码" v-model="editForm.password" clearable maxlength="18"></el-input>
                </el-form-item>



                <el-row>
                    <el-col :span="10">

                        <el-form-item label="地址" prop="selectedOptions">
                            <el-cascader size="large" :options="pcaTextArr" v-model="editForm.selectedOptions">
                            </el-cascader>
                        </el-form-item>

                    </el-col>
                    <el-col :span="4">
                        &nbsp;
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="详细地址" prop="address">
                            <el-input placeholder="详细地址信息" v-model="editForm.address" clearable></el-input>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-form-item label="备注信息" prop="remark">
                    <el-input placeholder="备注信息" v-model="editForm.remark" clearable></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" style="text-align: center;display:block;">
                <el-button @click="edit_user = false">取 消</el-button>
                <el-button type="primary" @click="up_submit">确 定</el-button>
            </span>
        </el-dialog>

        <div class="manage-header">

            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm">
                <el-form-item>
                    <el-input placeholder="手机号码" v-model="userForm.phone" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="姓名" v-model="userForm.user_name" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>

            <el-button @click="handleAdd" type="primary">
                + 新增
            </el-button>
        </div>
        <div class="common-tabel">
            <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading"
                element-loading-text="拼命加载中" :row-style="{ height: '10px' }" highlight-current-row>
                <el-table-column type="index" label="序号">
                    <template slot-scope="scope">
                        {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="admin_name" label="用户名" width="100">
                </el-table-column>
                <el-table-column prop="account" label="账号/电话" width="150">
                </el-table-column>
                <el-table-column prop="login_time" label="上次登录时间" width="160">
                </el-table-column>
                <el-table-column prop="login_address" label="上次登录地址" width="120">
                </el-table-column>
                <el-table-column prop="identity" label="身份" width="80">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.identity === '管理员' ? 'primary' : 'success'"
                            disable-transitions>{{ scope.row.identity }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="id_card" label="身份证" width="200">
                </el-table-column>
                <el-table-column prop="area" label="区域" width="170">
                </el-table-column>
                <el-table-column prop="address" label="详细地址" width="200">
                </el-table-column>
                <el-table-column prop="remark" label="备注信息" width="100">
                </el-table-column>
                <el-table-column prop="addr" label="编辑" width="180" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="edit(scope.row)"><i
                                class="el-icon-edit"></i>编辑</el-button>
                        <!-- <el-button type="danger" size="mini" @click="handleDelete(scope.row)"><i
                                class="el-icon-delete"></i>删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>


    </div>
</template>
<script>

import { admin_user_info, send_sms_api, add_admin, update_admin_user, del_admin_user } from '../api'
import { provinceAndCityData, pcTextArr, regionData, pcaTextArr, codeToText } from 'element-china-area-data'

export default {
    data() {
        return {
            dialogVisible: false,
            edit_user: false,
            editForm: {
                account: '',
                selectedOptions: [''],
                user_type: '',
                id_card:'',
                password:'',
                address: '',
                remark: '',
            },
            pcaTextArr,
            loading: true,
            show_detail: false,
            currentPage1: 1,
            // 当前内容的
            ruleForm: {
                phone: '',
                ver_code: '',
                user_name: '',
                id_card: '',
                remark: '',
                password: '000000',
                selectedOptions: [],
                address: '',
                user_type: '',
                user_nature: 'admin' // 定义用户性质(管理员>成员>老师)
            },
            sub_btn: false,
            isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
            content: '发送', // 发送验证码按钮的初始显示文字
            timer: null,
            count: '',
            rules: {
                phone: [
                    { required: true, message: '请输入手机号码!' }
                ],
                ver_code: [
                    { required: true, message: '请输入手机获取到的短信验证码!' }
                ],
                user_name: [
                    { required: true, message: '请输入姓名!' }
                ],
                user_type: [
                    { required: true, message: '请选择用户权限!' }
                ]
            },
            options: [{
                value: '管理员',
                label: '管理员'
            }, {
                value: '成员',
                label: '成员'
            }],
            props: { multiple: true },
            tableData: [],
            modalType: 0, // 0表示新增的弹窗, 1表示编辑
            total: 0, //当前的总条数
            pageData: {
                page: 1,
                limit: 10
            },
            userForm: {
                phone: '',
                user_name: '',
                user_nature: 'admin',
            },
        }
    },
    methods: {
        // 提交用户表单
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!');
                    console.log(this.ruleForm)
                    add_admin({ data: this.ruleForm }).then(({ data }) => {
                        if (data.code == 200) {
                            this.$message({
                                type: 'success',
                                message: data.msg
                            })
                            this.getList()

                            // 清空表单的数据
                            this.$refs[formName].resetFields();
                            // 关闭弹窗
                            this.dialogVisible = false
                        } else {
                            this.$message({
                                type: 'error',
                                message: data.msg
                            })
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        up_submit() {
            update_admin_user({ data: this.editForm }).then(({ data }) => {
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: data.msg
                    })
                    this.getList()

                    // 关闭弹窗
                    this.edit_user = false
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            })
        },

        // 弹窗关闭的时候
        handleClose(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
        },
        handleClose_edit(formName){
            this.$refs[formName].resetFields();
            this.edit_user = false
        },
        cancel(formName) {
            this.$refs[formName].resetFields();
        },
        handleDelete(row) {
            this.$confirm('此操作将永久删除该用户信息, 且如果管理员或者成员将删除其创建的附属信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                del_admin_user({ params: row }).then(({ data }) => {
                    if (data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }

                    // 重新获取列表的接口
                    this.getList()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleAdd() {
            this.modalType = 0
            this.dialogVisible = true
        },
        // 获取列表的数据
        getList() {
            // 获取的列表的数据
            admin_user_info({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                console.log(data)
                this.tableData = data.data

                this.total = data.count || 0
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageData.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageData.page = val
            console.log(this.pageData)
            this.getList()

        },
        // 选择页码的回调函数
        handlePage(val) {
            // console.log(val, 'val')
            this.pageData.page = val
            this.getList()
        },
        // 列表的查询
        onSubmit() {
            // this.pageData.page = 1
            this.getList()
        },
        getExercise() {
            admin_user_info({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                this.tableData = data.data
                this.total = data.count
                this.loading = false
            })
        },
        send_sms() {
            this.isDisabled = true;
            console.log('发送短信')
            const phone = this.ruleForm.phone
            if (!this.isMobile(phone)) {
                this.$message.error('请输入正确的手机号码格式！')
                this.isDisabled = false;
                return;
            }
            send_sms_api({ params: { ...this.ruleForm } }).then(({ data }) => {
                if (data.code == 200) {

                    const TIME_COUNT = 60;
                    if (!this.timer) {
                        this.count = TIME_COUNT;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--;
                                console.log(this.count)
                                this.content = this.count + '秒后重试 '
                            } else {
                                this.isDisabled = false;
                                clearInterval(this.timer);
                                this.timer = null;
                                this.content = '发送'
                            }
                        }, 1000)
                    }
                } else {
                    this.$message.error(data.msg)
                    this.isDisabled = false;
                }

            })

        },
        isMobile(val) {
            return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(val);
        },
        edit(val) {
            console.log(val)
            this.edit_user = true
            this.editForm.account = val.account
        }

    },
    mounted() {
        admin_user_info({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
            this.total = data.count
            this.loading = false
        })

    }
}
</script>
<style lang="less" scoped>
.manage {
    height: 90%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 1px);

        .pager {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }
}
</style>