<template>
    <div class="manage">
        <div class="manage-header">

            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm">
                <el-form-item>
                    <el-input placeholder="手机号码" v-model="userForm.phone" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="姓名" v-model="userForm.user_name" clearable></el-input>
                </el-form-item>
                <el-form-item v-show="identity != '老师'">
                    <el-select v-model="userForm.select_value" filterable placeholder="下级选择"  clearable>
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="common-tabel">
            <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading"
                element-loading-text="拼命加载中" :row-style="{ height: '10px' }" highlight-current-row>
                <el-table-column type="index" label="序号">
                    <template slot-scope="scope">
                        {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="用户名" width="100">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="150">
                </el-table-column>
                <el-table-column prop="car" label="车型" width="200">
                </el-table-column>
                <el-table-column prop="on_subject" label="学习科目" width="200">
                </el-table-column>
                <el-table-column prop="on_time" label="学习时间" width="220">
                </el-table-column>
                <el-table-column prop="progress" label="学习进度" width="120">
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="18" :percentage="scope.row.progress" status="success"></el-progress>
               </template>
                </el-table-column>
                <el-table-column prop="period" label="剩余学时(时)" width="120">
                </el-table-column>
                <el-table-column prop="sum_period" label="总学时(时)" width="120">
                </el-table-column>
                <el-table-column prop="registrations" label="报名时间" width="220">
                </el-table-column>
                <el-table-column prop="create_user" label="创建人" width="220">
                    <template slot-scope="scope">
                        <el-tag type="success"
                            disable-transitions>{{ scope.row.create_user }}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import { app_user_study, admin_info, teacher_name} from '../api'

export default ({
    data() {
        return {

            userForm: {
                phone: '',
                user_name: '',
                select_value: '',
            },
            total: 0, //当前的总条数
            tableData: [],
            loading: true,
            pageData: {
                page: 1,
                limit: 10
            },
            currentPage1: 1,
            options1: [],
            identity: '',
        }
    },
    methods: {

        onSubmit() {
            // this.pageData.page = 1
            this.getList()
        },

        getList() {
            // 获取的列表的数据
            app_user_study({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                console.log(data)
                this.tableData = data.data

                this.total = data.count || 0
            })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageData.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageData.page = val
            console.log(this.pageData)
            this.getList()

        },
        

    },
    mounted() {
        app_user_study({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
            this.total = data.count
            this.loading = false
        }),
        admin_info().then(({ data }) =>{
            //this.user_info = data.data
            this.identity = data.identity
        })

        teacher_name().then(({ data }) =>{
            //this.user_info = data.data
            // this.identity = data.identity
            this.options1 = data.data
        })

    }
})


</script>

<style lang="less" scoped>
.manage {
    height: 90%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 1px);

        .pager {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }
}
</style>