<template>
    <el-container>
        <el-header>

        </el-header>
        <el-main class="login">
            <div>
                <el-form :rules="rules" ref="loginForm" :model="loginForm" class="loginContainer">
                    <h3 class="loginTitle">
                        易点通管理后台
                    </h3>
                    <el-form-item prop="username">
                        <el-input type="text" v-model="loginForm.username" placeholder="亲，请输入用户名" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input type="password" v-model="loginForm.password" placeholder="亲，请输入密码" show-password>
                        </el-input>
                    </el-form-item>

                    <!-- 
                    <el-form-item prop="code">
                        <el-input type="text" auto-complete="false" v-model="loginForm.code" placeholder="点击图片更换验证码"
                            style="width: 250px;margin-right: 5px">
                        </el-input>
                        <img :src="captchaUrl">
                    </el-form-item> 
                    -->

                    <el-checkbox v-model="loginForm.checked" class="loginRemember">记住我</el-checkbox>
                    <el-button type="primary" style="width:100%" @click="submitLogin">登录</el-button>
                </el-form>
            </div>
        </el-main>
        <el-footer>
            <div style="padding-top:30px">
                <span style="text-align: center;display:block;">
                    
                </span>
		 
            </div>
            <div style="width:230px;margin:0 auto; padding:20px 0;">
                <a href="https://beian.miit.gov.cn/" target="_blank" style="padding-left: 15%;padding-bottom: 20px;">赣ICP备2023001880号-1</a>
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36042902000036" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">赣公网安备 36042902000036号</p></a>
		 	</div>
		 
            
        </el-footer>
    </el-container>
</template>

<script>
import { login } from '../api'

import Cookie from 'js-cookie'

export default {
    name: "Login",
    data() {
        return {
            captchaUrl: "",
            loginForm: {
                // username: "18902222484",
                // password: "Admin123",
                username: "",
                password: "",
                code: '',
                checked: false,
            },
            
            rules: {
                username: [{ required: true, message: "请输入用户名", trigger: "blur" }, { min: 5, max: 14, message: '长度在 5 到 14 个字符', trigger: 'blur' }
                ],
                password: [{ required: true, message: "请输入密码", trigger: "blur" }, , { min: 6, message: '密码长度要大于6', trigger: 'blur' }],
                // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
            }

        }
    },
    methods: {
        submitLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    login({ data: this.loginForm }).then(({ data }) => {
                        if (data.code == 200){
                            console.log('access_token')
                            Cookie.set('admin_token', data.access_token)
                            this.$message.success('登录成功!')
                            // 获取菜单的数据, 存入store中
                            localStorage.setItem("menu",  JSON.stringify(data.menu));
                            this.$store.commit('setMenu', data.menu)
                            this.$store.commit('addMenu', this.$router)
                            this.$router.push('/home')
                        } else if(data.code == 500) {
                            this.$message.error('账号或密码错误')
                        }
                    }).catch(err => {
                        // this.$message.error('账号或密码错误')
                    })
                } else {
                    this.$message.error('登录出错请重新输入');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="less" >
.loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 180px auto;
    width: 350px;
    padding: 15px 35px 15px 35px;
    background: aliceblue;
    border: 1px solid blueviolet;
    box-shadow: 0 0 25px #f885ff;
}

.loginTitle {
    margin: 0px auto 48px auto;
    text-align: center;
    font-size: 40px;
}

.loginRemember {
    text-align: left;
    margin: 0px 0px 15px 0px;
}

.login {
    background-image: url("https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E5%88%9B%E5%A7%8B%E4%BA%BA%E4%BB%8B%E7%BB%8D.jpg");
    background-size: 100%;
    height: 100%;
}
</style>

