<template>
    <div class="manage">
        <div class="manage-header">

            <!-- form搜索区域 -->
            <el-form :inline="true" :model="userForm">
                <el-form-item>
                    <el-input placeholder="手机号码" v-model="userForm.phone" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input placeholder="姓名" v-model="userForm.user_name" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="PayTime" type="daterange" align="right" unlink-panels range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" value-format="yyyy-MM-dd" @change="GetzhifuTime">
                    </el-date-picker>
                </el-form-item>
                <el-form-item  v-show="identity != '老师'">
                    <el-select v-model="userForm.select_value" filterable placeholder="下级选择"  clearable>
                        <el-option
                        v-for="item in options1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog title="修改订单金额信息" :visible.sync="edit_user" width="50%">
            <!-- 用户的表单信息 -->
            <el-form ref="form" :model="editForm" label-width="80px">


                <el-form-item label="费用" prop="cost">
                    <el-input placeholder="人民币(元)" v-model="editForm.cost" clearable type="number"></el-input>
                </el-form-item>

                <el-form-item label="返点(元)" prop="amount">
                    <el-input placeholder="人民币(元)" v-model="editForm.amount" clearable type="number"></el-input>
                </el-form-item>

            </el-form>

            <span slot="footer" style="text-align: center;display:block;">
                <el-button @click="edit_user = false">取 消</el-button>
                <el-button type="primary" @click="up_submit">确 定</el-button>
            </span>
        </el-dialog>


        <div class="common-tabel">
            <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading"
                element-loading-text="拼命加载中" :row-style="{ height: '10px' }" highlight-current-row show-summary
                ref="customerReportDateTable">
                <el-table-column type="index" label="序号" width="50">
                    <template slot-scope="scope">
                        {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="用户名" width="200">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="250">
                </el-table-column>
                <el-table-column prop="subject" label="科目" width="500">
                </el-table-column>
                <el-table-column prop="amount" label="订单金额" width="100">
                </el-table-column>
                <el-table-column prop="brokerage" label="返点" width="100">
                </el-table-column>
                <el-table-column prop="practical" label="实收" width="100">
                </el-table-column>
                <el-table-column prop="log_time" label="创建时间" width="220">
                </el-table-column>
                <el-table-column prop="create_user" label="创建人" width="180">
                    <template slot-scope="scope">
                        <el-tag type="success"
                            disable-transitions>{{ scope.row.create_user }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="addr" label="编辑" width="90" fixed="right">
                    <template slot-scope="scope">
                        <!-- <el-button type="danger" size="mini" @click="handleDelete(scope.row)"><i
                                class="el-icon-remove-outline"></i>退费</el-button> -->
                        <el-button type="primary" size="mini" @click="edit(scope.row)"><i
                                class="el-icon-edit"></i>修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

import { app_user_order, refund, update_refund, admin_info, teacher_name} from '../api'

export default ({
    data() {
        return {

            userForm: {
                phone: '',
                user_name: '',
                start_time: '',
                end_time: '',
                select_value: '',
            },
            edit_user: false,
            PayTime: '',
            total: 0, //当前的总条数
            tableData: [],
            loading: true,
            pageData: {
                page: 1,
                limit: 10
            },
            currentPage1: 1,
            editForm: {
                openid: ''
            },
            identity: '',
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },

        }
    },
    methods: {

        onSubmit() {
            // this.pageData.page = 1
            this.getList()
        },

        getList() {
            // 获取的列表的数据
            app_user_order({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
                console.log(data)
                this.tableData = data.data

                this.total = data.count || 0
            })
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageData.limit = val
            this.getList()

        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageData.page = val
            console.log(this.pageData)
            this.getList()

        },

        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (column.property == 'amount') {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += ' 元';
                } else {
                    sums[index] = ' ';
                }
            });

            return sums;
        },

        GetzhifuTime() {
				 //console.log(this.PayTime)
				if (this.PayTime != null) {
					this.userForm.start_time = this.PayTime[0]
					this.userForm.end_time = this.PayTime[1]
				}
		},
        handleDelete(row) {
            this.$confirm('此操作将退回用户缴纳费用并取消可学习科目, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                refund({ params: row }).then(({ data }) => {
                    if (data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }

                    // 重新获取列表的接口
                    this.getList()
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消退费'
                });
            });
        },
        edit(val) {
            console.log(val)
            this.editForm = {}
            this.editForm.openid = val.record_id
            this.edit_user = true
        },

        up_submit() {
            update_refund({ data: this.editForm }).then(({ data }) => {
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: data.msg
                    })
                    this.getList()

                    // 关闭弹窗
                    this.edit_user = false
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            })
        },


    },
    mounted() {
        app_user_order({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
            this.total = data.count
            this.loading = false
        }),
        admin_info().then(({ data }) =>{
            //this.user_info = data.data
            this.identity = data.identity
        })

        teacher_name().then(({ data }) =>{
            //this.user_info = data.data
            // this.identity = data.identity
            this.options1 = data.data
        })

    },
    updated() {

        this.$nextTick(() => {

            this.$refs.customerReportDateTable.doLayout();

        });

    },
})


</script>

<style lang="less" scoped>
.manage {
    height: 90%;

    .manage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .common-tabel {
        position: relative;
        height: calc(100% - 1px);

        .pager {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }
}
</style>