import Vue from 'vue'
import ElementUI from 'element-ui'
// import audio from 'vue-mobile-audio'
import VueCoreVideoPlayer from 'vue-core-video-player'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import Cookie from 'js-cookie'

import 'lib-flexible'


// 生成接口的模拟数据, 上线后取消
// import './api/mock'

Vue.config.productionTip = false

// 全局引用
Vue.use(VueCoreVideoPlayer)

Vue.use(ElementUI);

// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  const token = Cookie.get('admin_token')
  const identity = Cookie.get('identity')
  console.log(identity)
  if (to.path === '/practice_weixin'){
    // 特殊处理微信公众号的习题练习跳转地址
    next()
  }else if (to.path === '/redirection_login'){
    // 特殊处理软件用户跳转登录管理后台
    next()
  }else if (to.path === '/phone_add_some_code'){
    // 特殊处理手机端创建激活码界面
    next()
  }else if  (!token && to.name !== 'login'){
    next({'name': 'login'})
  } else if (token && to.name === 'login'){
    next({'name': 'home'})
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  created(){
    store.commit('addMenu', router)
  },
  render: h => h(App),
}).$mount('#app')
