<template>
  <el-skeleton style="width:100%" :loading="isloading" animated :count="3" :rows="6">
    <div class="big-block">

      <el-col :span="4" style="padding-right: 10px">
        <div class="grid-content bg-purple">
          <el-card class="box-left" style="height: 900px">
            <p align="center" style="color:red">{{ titleinfo }}</p>
            <h2 align="center">模拟考试</h2>
            <img
              src="https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E8%93%9D%E8%89%B2%E5%BA%95%E7%89%88%E6%9C%AC.jpg"
              alt="头像">
            <h4>考生信息: <span class="spaninfo">XX易点通驾考XXX</span></h4>
            <h4>姓名: <span class="spaninfo">刘XXXXX</span></h4>
            <h4>学号: <span class="spaninfo">18903452345</span></h4>
            <h4>题型: <span class="spaninfo">{{ titleinfo }}</span></h4>
            <el-card shadow="hover">
              <div slot="header" class="clearfix">
                <span><i class="el-icon-timer"></i>倒计时</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="clickFn">暂停</el-button>
              </div>
              <div style="margin-top: 40px;"></div>
              <el-statistic ref="statistic" @finish="hilarity" format="HH:mm:ss" :value="deadline2" title="距离结束："
                time-indices>
              </el-statistic>
            </el-card>
            <el-row>
              <el-button type="warning" round style="margin-top: 60px; margin-left: 30%" @click="jiaojuan"><i
                  class="el-icon-tickets"></i>交卷</el-button>
            </el-row>
          </el-card>
        </div>
      </el-col>
      <el-col :span="13">
        <div class="grid-content bg-purple-light">
          <el-card class="box-center" style="height: 900px">
            <el-container>
              <el-header style="height: 800px">
                <!-- 主题部分 -->
                <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                  <!-- 循环后端给你的所有题 -->
                  <div class="VSTD_box_item" v-for="(item, index) in question" :key="item.id">

                    <!-- 1 单选 -->
                    <!-- 2 判断 -->
                    <!-- 3 多选 -->
                    <!-- 4 简答 -->

                    <div class="VSTD_box_item_title">

                      <!-- 题目的信息 -->

                      <p style="font-weight: 700">
                        <span v-if="item.questionType == 1"><el-tag type="primary">单选题</el-tag></span>
                        <span v-if="item.questionType == 2"><el-tag type="warning">判断题</el-tag></span>
                        <span v-if="item.questionType == 3"><el-tag type="danger">多选题</el-tag></span>
                        <span style="font-size:25px"> 第{{ item.sequence_number }}题：{{ item.problem }} </span>

                      </p>
                    </div>

                    <el-form-item label="">
                      <img :src="item.picture" alt="插画" :class="item.picture ? incrseP : downP">
                    </el-form-item>

                    <!-- 如果questionType 等于1 那么他是单选题 -->
                    <!-- 题目绑定的值是 singlevalue  -->

                    <div v-if="item.questionType == 1">
                      <el-form-item label="" prop="resource">
                        <el-radio-group v-model="singlevalue" class="VSTD_box_item_select" @input="single"
                          :disabled="canclick">
                          <el-radio :label="item.option_a.info">A.{{ item.option_a.info }}
                            <span v-if="singlevalueicon === item.option_a.info"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span v-else-if="(singlevalueicon !== item.option_a.info) && (singlevalueicon != '')"><i
                                class="el-icon-error" style="color:red;"></i></span>

                          </el-radio><br />
                          <el-radio :label="item.option_b.info">B.{{ item.option_b.info }}
                            <span v-if="singlevalueicon === item.option_b.info"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span v-else-if="(singlevalueicon !== item.option_b.info) && (singlevalueicon != '')"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-radio><br />
                          <el-radio :label="item.option_c.info">C.{{ item.option_c.info }}
                            <span v-if="singlevalueicon === item.option_c.info"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span v-else-if="(singlevalueicon !== item.option_c.info) && (singlevalueicon != '')"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-radio><br />
                          <el-radio :label="item.option_d.info">D.{{ item.option_d.info }}
                            <span v-if="singlevalueicon === item.option_d.info"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span v-else-if="(singlevalueicon !== item.option_d.info) && (singlevalueicon != '')"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-radio><br />
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <!-- 如果questionType 等于2 那么他是判断题 -->
                    <!-- 题目绑定的值是 judgmentvalue  -->

                    <div v-if="item.questionType == 2">
                      <el-form-item label="" prop="resource">
                        <el-radio-group v-model="judgmentvalue" class="VSTD_box_item_select" @input="judgment"
                          :disabled="canclick">
                          <el-radio label="对">A.{{ item.option_a.info }}
                            <i class="el-icon-success" style="color:lightgreen;" :class="iconstatus1"></i>
                            <i class="el-icon-error" style="color:red;" :class="iconstatus2"></i>
                          </el-radio><br />
                          <el-radio label="错">B.{{ item.option_b.info }}
                            <i class="el-icon-success" style="color:lightgreen;" :class="iconstatus2"></i>
                            <i class="el-icon-error" style="color:red;" :class="iconstatus1"></i>
                          </el-radio><br />
                        </el-radio-group>
                      </el-form-item>
                    </div>

                    <!-- 如果questionType 等于3 那么他是多选题 -->
                    <!-- 题目绑定的值是 ruleForm.resource[index]  -->

                    <div v-if="item.questionType == 3">
                      <el-form-item label="" prop="resource">
                        <el-checkbox-group v-model="multiplevalue" class="VSTD_box_item_select" :disabled="canclick">
                          <el-checkbox :label="item.option_a.info">{{ item.option_a.info }}

                            <span v-if="multipleanswer.includes(item.option_a.info)"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span
                              v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_a.info))"><i
                                class="el-icon-error" style="color:red;"></i></span>

                          </el-checkbox><br />
                          <el-checkbox :label="item.option_b.info">{{ item.option_b.info }}
                            <span v-if="multipleanswer.includes(item.option_b.info)"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span
                              v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_b.info))"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-checkbox><br />
                          <el-checkbox :label="item.option_c.info">{{ item.option_c.info }}
                            <span v-if="multipleanswer.includes(item.option_c.info)"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span
                              v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_c.info))"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-checkbox><br />
                          <el-checkbox :label="item.option_d.info">{{ item.option_d.info }}
                            <span v-if="multipleanswer.includes(item.option_d.info)"><i class="el-icon-success"
                                style="color:lightgreen;"></i></span>
                            <span
                              v-else-if="multipleanswer.length != 0 && !(multipleanswer.includes(item.option_d.info))"><i
                                class="el-icon-error" style="color:red;"></i></span>
                          </el-checkbox><br />
                        </el-checkbox-group>
                      </el-form-item>
                      <el-form-item style="text-align: center">
                        <el-button type="primary" @click="submitForm" circle style="float:right">提交</el-button>
                      </el-form-item>
                    </div>

                  </div>

                  <!-- 提交函数  -->


                </el-form>


                <el-row style="padding-bottom: 20px;">
                  <el-button icon="el-icon-chat-dot-square" @click="jiqiao">技巧</el-button>
                  <el-button icon="el-icon-microphone" @click="yuyin" :disabled="yuyinbtn">语音</el-button>
                  <el-button icon="el-icon-video-play" @click="videoed">视频</el-button>

                  <el-button icon="el-icon-info" @click="uuid_info" style="float:right">唯一标识</el-button>
                </el-row>


                <span :class="skill == true ? showskill : noskill">{{ question[0].explains.skill.text }}</span>
                <el-dialog title="讲解视频" :visible.sync="centerDialogVisible" width="60%" center @open="vopen"
                  @close="vclose">
                  <commonVideo :videoSource="videoSource" ref="child" />
                </el-dialog>
                <el-divider></el-divider>
              </el-header>
              <el-main>

                <!-- 底座部分 -->
                <el-button type="primary" icon="el-icon-arrow-left" style="float:left" @click="shangyiti">上一题</el-button>
                <span style="font-size:30px; margin-left: 33%;">
                  <i class="el-icon-success" style="color:lightgreen;"></i>{{ zhengque }}
                  <i class="el-icon-error" style="color:red;"></i>{{ cuowu }}
                </span>

                <el-button type="primary" style="float:right" @click="xiyiti">下一题<i
                    class="el-icon-arrow-right el-icon--right"></i></el-button>

              </el-main>
            </el-container>


          </el-card>
        </div>
      </el-col>
      <el-col :span="7" style="padding-left: 10px">
        <div class="grid-content bg-purple">
          <el-card class="box-right" style="height: 900px">

            <el-card class="box-card">
              <el-tag v-for="(item, index) in tagstatus" :key="index" @click="changesubject(index)"
                style="margin-right:10px;margin-top: 5px; width: 40px" hit :type="item">
                {{ index + 1 }}
              </el-tag>
            </el-card>


            <el-divider></el-divider>
            <div>
              <span>
                <span>颜色说明:</span>
                <el-tag type="info" hit style="margin-right: 50px; margin-left: 20px;">未答题</el-tag>
                <el-tag type="success" hit style="margin-right: 50px">正确题</el-tag>
                <el-tag type="danger" hit>错误题</el-tag>
              </span>
            </div>

            <el-divider></el-divider>
            <div style="margin-bottom: 40px; ">
              正确率
              <el-progress :text-inside="true" :stroke-width="26" :percentage="zhengquelv()"
                status="success"></el-progress>
              <br />
              完成率
              <el-progress :text-inside="true" :stroke-width="26" :percentage="datilv()"></el-progress>
            </div>


            <div class="block">
              <el-carousel height="220px">
                <el-carousel-item v-for="item in lunbotu" :key="item">
                  <img :src="item" alt="简介" style="width:100%; height: 100%;">
                </el-carousel-item>
              </el-carousel>
            </div>

          </el-card>
        </div>
      </el-col>
    </div>
  </el-skeleton>
</template>

<script>
import commonVideo from '../components/commonVideo.vue'
import { parcticeinfo } from '../api'


export default {
  data() {
    return {
      incrseP: 'Apirture',
      downP: 'pirture',
      noskill: 'noskill',
      showskill: 'showskill',
      iconstatus1: 'iconstatus1',
      iconstatus2: 'iconstatus2',
      skill: false,
      yuyinbtn: false,
      centerDialogVisible: false,
      judgmentvalue: '',
      singlevalue: '',
      singlevalueicon: '',
      multiplevalue: [],
      multipleanswer: [],
      canclick: false,
      zhengque: 0,
      cuowu: 0,

      lunbotu: [
        'https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E5%88%9B%E5%A7%8B%E4%BA%BA%E4%BB%8B%E7%BB%8D.jpg',
        'https://user-picture-1257386772.cos.ap-nanjing.myqcloud.com/%E8%A7%86%E9%A2%91%E5%B0%81%E9%9D%A2.jpg'
      ],

      sum_question: [],
      question: [{ "option_a": { "info": "对", "correct": true }, "option_b": { "info": "错", "correct": false }, "option_c": { "info": "", "correct": false }, "option_d": { "info": "", "correct": false }, 'explains': { 'skill': '' } }],


      // 存储用户的答案回显
      sub_value: {},

      // 存储tag标签的状态
      tagstatus: [],

      // 请求接口成功前使用骨架屏
      isloading:true,

      titleinfo: '',

      deadline2: Date.now() + 1000 * 60 * 60 * 1.5,
      stop: true,
      ruleForm: {
        resource: ['A', '', [], []],				//每一道题的值
      },
      videoSource: ''
    };
  },
  components: {
    commonVideo
  },
  methods: {
    hilarity() {
      this.$notify({
        title: '提示',
        message: '时间已到，你可知寸金难买寸光阴？',
        duration: 0,
      });
    },
    clickFn() {
      this.$refs.statistic.suspend(this.stop);
      this.stop = !this.stop;
    },
    changesubject(item) {
      this.question = [this.sum_question[item]]
      this.nextone()
    },
    change() {
      this.$forceUpdate()
    },
    jiqiao() {
      this.skill = !this.skill
      if (this.skill === true) {
        console.log(this.dialog)
        var myAudio = new Audio();
        myAudio.preload = true;
        myAudio.controls = true;
        myAudio.src = this.question[0].skill_mp3; // 每次读数组最后一个元素
        myAudio.play();
      }

    },
    jiaojuan() {
      let s = '共做答题 ' + Object.keys(this.sub_value).length + ' 个, 正确: ' + this.zhengque + ' 个, 错误： ' + this.cuowu + ' 个'
      console.log(s)
      this.$alert(s, '考试结果', {
        confirmButtonText: '确定'
      });
    },
    uuid_info() {
      this.$notify.info({
        title: '消息',
        message: this.question[0].uuid
      })
    },
    sleep(ms) { //sleep延迟方法2
      var unixtime_ms = new Date().getTime();
      while (new Date().getTime() < unixtime_ms + ms) { }
    },
    yuyin() {

      const mp3 = this.question[0].mp3
      let arr = JSON.parse(JSON.stringify(mp3))

      var myAudio = new Audio();
      myAudio.preload = true;
      myAudio.controls = true;
      myAudio.src = arr.pop(); // 每次读数组最后一个元素
      myAudio.addEventListener('ended', playEndedHandler, false);
      myAudio.play();
      console.log(arr.length)
      myAudio.loop = false; // 禁止循环，否则无法触发ended事件
      function playEndedHandler() {
        myAudio.src = arr.pop();
        myAudio.play();
        !arr.length && myAudio.removeEventListener('ended', playEndedHandler, false);//只有一个元素时解除绑定
      }

    },
    videoed() {
      if (this.question[0].video === '') {
        console.log('没有视频')
        this.$message('暂无视频讲解！');
      } else {
        this.centerDialogVisible = true
        this.videoSource = this.question[0].video
      }
    },
    vopen() {
      try {
        this.$refs.child.vstart();
        //播放
      } catch (error) {
        console.log('第一次打开视频异常问题');
      }

    },
    // 关闭dialog同时关闭视频
    vclose() {
      this.$refs.child.vstop();
    },


    judgment(val) {
      this.canclick = true
      console.log('点击了')
      console.log(val);
      this.judgmentvalue = val
      let answer = this.question[0].answer
      let on_number = this.question[0].sequence_number

      let info = this.question[0]
      let one = {}
      let uuid = info.uuid
      one['canclick'] = this.canclick
      one['judgmentvalue'] = val

      if (val == answer) {
        console.log('答对了')
        this.tagstatus[on_number - 1] = 'success'
        this.zhengque = this.zhengque + 1
        // 答对了直接跳到下一题
        this.xiyiti()
      } else {
        console.log('答错了')
        this.tagstatus[on_number - 1] = 'danger'
        this.cuowu = this.cuowu + 1
        if (answer === '对') {
          this.iconstatus1 = 'iconshow'
          one['iconstatus1'] = 'iconshow'
        } else {
          this.iconstatus2 = 'iconshow'
          one['iconstatus2'] = 'iconshow'
        }
      }

      this.sub_value[uuid] = one

      console.log(this.sub_value)
    },
    single(val) {
      this.canclick = true
      console.log('点击了')
      console.log(val);

      let info = this.question[0]
      let one = {}
      let uuid = info.uuid
      one['canclick'] = this.canclick
      let on_number = this.question[0].sequence_number


      let answer = this.question[0].answer
      console.log(answer)
      if (val == answer) {
        console.log('答对了')
        this.tagstatus[on_number - 1] = 'success'
        this.singlevalue = val
        this.zhengque = this.zhengque + 1
        // 答对了直接跳到下一题
        this.xiyiti()
      } else {
        console.log('答错了')
        this.tagstatus[on_number - 1] = 'danger'
        this.singlevalue = val
        this.singlevalueicon = answer

        one['singlevalueicon'] = answer
        this.cuowu = this.cuowu + 1
      }
      one['singlevalue'] = val
      this.sub_value[uuid] = one

      console.log(this.sub_value)

    },
    submitForm() {
      if (this.canclick) {
        this.$message({
          showClose: true,
          message: '已经提交过了！',
          type: 'warning'
        })
        return
      }
      let answer = this.question[0].answer
      let arr = answer.split("; ")
      console.log(this.multiplevalue)
      this.canclick = true

      // 存储提交过的题目状态
      let info = this.question[0]
      let one = {}
      let uuid = info.uuid
      one['canclick'] = this.canclick

      let on_number = this.question[0].sequence_number

      if (arr.length === this.multiplevalue.length && arr.sort().toString() === this.multiplevalue.sort().toString()) {
        console.log('答对了')
        this.tagstatus[on_number - 1] = 'success'
        //this.multipleanswer = arr
        one['multiplevalue'] = arr
        this.zhengque = this.zhengque + 1
        // 答对了直接跳到下一题
        this.xiyiti()
      } else if (this.multiplevalue.length == 0) {
        this.$message({
          showClose: true,
          message: '请选择答案后提交！',
          type: 'warning'
        })
      } else {
        console.log('答错了, 显示正确答案')
        this.tagstatus[on_number - 1] = 'danger'
        one['multiplevalue'] = this.multiplevalue
        this.multipleanswer = arr
        one['multipleanswer'] = arr

        this.cuowu = this.cuowu + 1
      }
      this.sub_value[uuid] = one

      console.log(this.sub_value)
    },
    shangyiti() {
      console.log('上一题')
      let on_number = this.question[0].sequence_number
      if (on_number == 1) {
        this.$message({
          showClose: true,
          message: '已经是第一题了！',
          type: 'warning'
        })
      } else {
        console.log('进来了', on_number)
        this.question = [this.sum_question[on_number - 2]]
        this.nextone()
      }
    },
    xiyiti() {
      console.log('下一题')
      console.log(this.question[0])
      let on_number = this.question[0].sequence_number
      if (on_number == this.sum_question.length) {
        this.$message({
          showClose: true,
          message: '已经是最后一题了！',
          type: 'warning'
        })
      } else {
        this.question = [this.sum_question[on_number]]
        this.nextone()
      }
    },
    nextone() {
      // 判断是否是做过的题
      let uuid = this.question[0].uuid
      let subject = this.sub_value[uuid]
      console.log(subject)
      if (typeof (subject) == "undefined") {
        console.log('没做过')
        // 开始下一题初始化个别参数
        this.noskill = 'noskill'
        this.showskill = 'showskill'
        this.iconstatus1 = 'iconstatus1'
        this.iconstatus2 = 'iconstatus2'
        this.skill = false
        this.yuyinbtn = false
        this.centerDialogVisible = false
        this.judgmentvalue = ''
        this.canclick = false
        this.singlevalue = ''
        this.singlevalueicon = ''
        this.multiplevalue = []
        this.multipleanswer = []
      } else {
        console.log('做过了')
        this.canclick = subject.canclick
        this.judgmentvalue = subject.judgmentvalue
        // 判断题
        if (typeof (subject.iconstatus2) != 'undefined') {
          this.iconstatus2 = subject.iconstatus2
          this.iconstatus1 = 'iconstatus1'
        }
        else if (typeof (subject.iconstatus1) != 'undefined') {
          this.iconstatus1 = subject.iconstatus1
          this.iconstatus2 = 'iconstatus2'
        } else {
          this.iconstatus1 = 'iconstatus1'
          this.iconstatus2 = 'iconstatus2'
        }

        // 单选题
        this.singlevalue = subject.singlevalue
        if (typeof (subject.singlevalueicon) != 'undefined') {
          this.singlevalueicon = subject.singlevalueicon
        } else {
          this.singlevalueicon = ''
        }

        //多选题
        if (typeof (subject.multipleanswer) != 'undefined') {
          this.multipleanswer = subject.multipleanswer
        } else {
          this.multipleanswer = []
        }
        if (typeof (subject.multiplevalue) != 'undefned') {
          this.multiplevalue = subject.multiplevalue
        } else {
          this.multiplevalue = []
        }
      }


    },
    // 计算百分比
    gteProgress(val1, val2) {
      // console.log(val1, '被除数', val2, '除数');
      if (val1 == 0 || val2 == 0) {
        return 0
      }
      console.log((Math.round(val1 / val2 * 10000) / 100.00))
      return (Math.round(val1 / val2 * 10000) / 100.00)
    },
    zhengquelv() {
      return this.gteProgress(this.zhengque, this.zhengque + this.cuowu)
    },
    datilv() {
      return this.gteProgress(Object.keys(this.sub_value).length, this.sum_question.length)
    }
  },
  mounted() {
    let car = this.$route.query.model
    let subject = this.$route.query.select
    for(var i = 0; i < subject.length; i++){
        if (subject[i] == '/') {
          subject = subject.replace('/','-'); // 注意替换之后就变成新数组了
        }
    }
    this.titleinfo = car + '-' + subject
    parcticeinfo({params:this.$route.query}).then(({ data }) => {
      console.log(data)
      this.sum_question = data.data
      this.question = [data.data[0]]
      console.log(this.question[0].option_a.info)

      for (var i = 0; i < this.sum_question.length; i++) {
        this.tagstatus.push('info')

      }
      this.isloading=false
    })

  }
};
</script>

<style lang="less" scoped>
.box-left {
  img {
    margin-left: 40px;
    width: 180px;
    height: 180px;
    border-radius: 10%;

  }

  .spaninfo {
    text-decoration: underline;
    color: #999999;
  }

}



.VSTD_box_item_select {
  // 修改选项框大小,字体颜色,上下间隙
  zoom: 200%;

  .el-radio {
    color: #000;
    padding-top: 20px;
  }
}

.box-right {

  .tagbtn {
    padding-left: 10px;
  }
}

.demo-ruleForm {

  // 处理选项款不靠左贴边问题
  /deep/.el-form-item__content {
    margin-left: 0px !important;
  }
}

.pirture {
  display: none;
}

.Apirture {
  margin-left: 30%;
  margin-top: 20px;
  // width: 380px;
  height: 260px;
  object-fit: cover;

}

.noskill {
  display: none;
}

.showskill {
  font-size: 20px;
  color: red;

}

.iconstatus1 {
  display: none;
}

.iconstatus2 {
  display: none;
}

.iconshow {
  display: inline;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.box-card {
  height: 360px;
  overflow-y: auto
    /* 开启滚动显示溢出内容 */
}</style>