<template>
  <div class="manage">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" @close="handleClose()">
      <!-- 用户的表单信息 -->
      <el-form ref="form" :rules="rules" :inline="true" :model="form" label-width="80px">

        <el-form-item label="车型" prop="car_model">
          <el-select v-model="form.car_model" placeholder="选择车型" @change="select_car(form.car_model)">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="科目" prop="subjects">
          <el-select v-model="form.subjects" placeholder="选择科目">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="类别" prop="category">
          <el-input placeholder="请输入类别名称" v-model="form.category"></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="class_name">
          <el-input placeholder="请输入分类名称" v-model="form.class_name"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <div class="manage-header">

      <!-- form搜索区域 -->
      <el-form :inline="true" :model="userForm">
        <el-form-item>
          <el-input placeholder="请输入车型" v-model="userForm.car_model" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入科目" v-model="userForm.subjects" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input placeholder="请输入分类" v-model="userForm.class_name" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <el-button @click="handleAdd" type="primary">
        + 新增
      </el-button>
    </div>
    <div class="common-tabel">
      <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
        :row-style="{ height: '10px' }" highlight-current-row>
        <el-table-column type="index" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="car_model" label="车型" width="200">
        </el-table-column>
        <el-table-column prop="subjects" label="科目" width="200">
        </el-table-column>
        <el-table-column prop="category" label="类目" width="200">
        </el-table-column>
        <el-table-column prop="class_name" label="分类" width="200">
        </el-table-column>
        <el-table-column prop="number" label="题量数" width="200">
        </el-table-column>
        <!-- <el-table-column label="视频地址" width="400" show-overflow-tooltip>
          <template slot-scope="scope">
            <a :href="scope.row.video_url" target="_blank" class="buttonText">{{ scope.row.video_url }}</a>
          </template>
        </el-table-column> -->
        <el-table-column prop="addr" label="编辑" width="450" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="edit(scope.row)"><i class="el-icon-edit"></i>编辑</el-button>
            <!-- <el-button type="info" size="mini" @click="handleClick(scope.row)"><i class="el-icon-video-camera"></i>视频</el-button> -->
            <el-button type="success" size="mini" @click="upload(scope.row)"><i class="el-icon-upload2"></i>导入</el-button>
            <el-button type="success" size="mini" @click="download(scope.row)"><i class="el-icon-download"></i>导出</el-button>
            <!-- <el-button type="warning" size="mini" @click="new_download(scope.row)"><i class="el-icon-tickets"></i>模板</el-button> -->
            <el-button type="danger" size="mini" @click="handleDelete(scope.row)"><i
                class="el-icon-delete"></i>删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage1"
          :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="修改相关信息" :visible.sync="centerDialogVisible2" width="60%" center :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" @close="jiqiao_close">

      <div style="padding-top: 20px;padding-bottom: 20px;">
        <el-card class="box-card">
          <ul>
            <li v-show="video_url != ''">
              <h4>视频</h4>
              <el-link :href="video_url" target="_blank" type="success">查看<i
                  class="el-icon-view el-icon--right"></i></el-link>
              <el-button type="danger" size="mini" icon="el-icon-delete" style="float: right;"
                @click="del_file(video_url)"></el-button>
            </li>
          </ul>
        </el-card>
      </div>

      <el-row class="upload">

        <el-col :span="12">
          <span>上传视频</span>
          <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".mp4" :limit=1
            :before-upload="beforeUpload1" :on-change="loadJsonFromFile1" :file-list="uploadFiles1" :disabled="sub_btn">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传<span>mp4</span>文件,且不超过<span>10GB</span></div>
          </el-upload>
          <el-progress :percentage="percentage1" v-show="per_status1"></el-progress>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible2 = false" :disabled="sub_btn" icon="el-icon-close">关
          闭</el-button>
        <el-button type="primary" :disabled="sub_btn" icon="el-icon-check" :loading="sub_btn" @click="put_jiqiao"
          style="margin-top: 30px;">确 认</el-button>
      </span>


    </el-dialog>


    <el-dialog title="导入新的题库" :visible.sync="centerDialogVisible1" width="25%" center :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" @close="jiqiao_close">

      <el-row class="upload">

        <el-col :span="12">
          <span>上传表格</span>
          <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".xlsx" :limit=1
            :before-upload="beforeUpload_excel" :on-change="loadJsonFromFile1" :file-list="uploadFiles1"
            :disabled="sub_btn">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传<span style="color: red;">xlsx</span>文件,且不超过<span>20MB</span></div>
          </el-upload>
        </el-col>
      </el-row>
      <el-progress :percentage="percentage1" v-show="per_status1" :stroke-width="16"></el-progress>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible1 = false" :disabled="sub_btn" icon="el-icon-close">关
          闭</el-button>
        <el-button type="primary" :disabled="sub_btn" icon="el-icon-check" :loading="sub_btn" @click="upload_execel"
          style="margin-top: 30px;">确 认</el-button>
      </span>


    </el-dialog>

  </div>
</template>
<script>
// import { getUser, addUser, editUser, delUser } from '../api'
import { classification, addclassification, delclassification, secretkey, update_iamge, edit_subject_type, download_exercise, add_exercise_excel, search_excel_progress } from '../api'

import COS from 'cos-js-sdk-v5';

const config = {
  // 存储桶名称
  Bucket: 'subject-picture-1257386772',
  // 地域
  Region: 'ap-nanjing'
}


export default {
  data() {
    return {
      dialogVisible: false,
      loading: true,
      currentPage1: 1,
      video_url: '',
      uploadFiles1: [],
      uploadFiles2: [],
      sub_btn: false,
      // 上传进度
      percentage1: 0,
      // 是否显示进度条
      per_status1: false,
      // 当前内容的
      now_id: '',
      form: {
        car_model: '',
        subjects: '',
        category: '',
        class_name: '',
      },
      rules: {
        car_model: [
          { required: true, message: '请选择车型' }
        ],
        subjects: [
          { required: true, message: '请选择科目' }
        ],
        category: [
          { required: true, message: '请输入类目' }
        ],
        class_name: [
          { required: true, message: '请输入分类' }
        ]
      },
      options: [{
        value: '小车',
        label: '小车'
      }, {
        value: '摩托车',
        label: '摩托车'
      }, {
        value: '客车',
        label: '客车'
      }, {
        value: '货车',
        label: '货车'
      }, {
        value: '三力',
        label: '三力'
      }],
      options1: [],
      tableData: [],
      modalType: 0, // 0表示新增的弹窗, 1表示编辑
      total: 0, //当前的总条数
      pageData: {
        page: 1,
        limit: 10
      },
      userForm: {
        car_model: '',
        subjects: '',
        class_name: '',
      },
      centerDialogVisible2: false,
      centerDialogVisible1: false,
      set_car_model: '',
      set_subjects: '',
      set_class_name: '',
    }
  },
  methods: {
    // 提交用户表单
    submit() {
      this.$refs.form.validate((valid) => {
        console.log(valid, 'valid')
        if (valid) {
          // 后续对表单数据的处理
          if (this.modalType === 0) {
            addclassification({ data: this.form }).then(({ data }) => {
              if (data.code == 200) {
                this.$message({
                  type: 'success',
                  message: data.msg
                })
              } else {
                this.$message({
                  type: 'error',
                  message: data.msg
                })
              }
            })
          }
          this.getList()

          // 清空表单的数据
          this.$refs.form.resetFields()
          // 关闭弹窗
          this.dialogVisible = false
        }
      })
    },
    // 弹窗关闭的时候
    handleClose() {
      this.$refs.form.resetFields()
      this.dialogVisible = false
    },
    cancel() {
      this.handleClose()
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该分类下的所有试题, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delclassification({ params: row }).then(({ data }) => {
          if (data.code == 200) {
            this.$message({
              type: 'success',
              message: data.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: data.msg
            });
          }

          // 重新获取列表的接口
          this.getList()
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    edit(val) {

      console.log(val)
      this.$prompt('请输入需要更改的分类名称', '修改分类名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        edit_subject_type({ data: val, uuid: value }).then(({ data }) => {
          if (data.code == 200) {
            this.getExercise()
            this.$message({
              type: 'success',
              message: data.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: data.msg
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消更改'
        });
      });

    },
    handleAdd() {
      this.modalType = 0
      this.dialogVisible = true
    },
    // 获取列表的数据
    getList() {
      // 获取的列表的数据
      classification({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
        console.log(data)
        this.tableData = data.data

        this.total = data.count || 0
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.limit = val
      this.getList()

    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageData.page = val
      console.log(this.pageData)
      this.getList()

    },
    // 选择页码的回调函数
    handlePage(val) {
      // console.log(val, 'val')
      this.pageData.page = val
      this.getList()
    },
    // 列表的查询
    onSubmit() {
      // this.pageData.page = 1
      this.getList()
    },
    upload(val) {
      console.log(val)
      this.centerDialogVisible1 = true
      this.percentage1 = 0
      this.set_car_model = val.car_model
      this.set_subjects = val.subjects
      this.set_class_name = val.class_name
    },
    set_progress(code){
      search_excel_progress({ params: { redis_key: code } }).then(({ data }) => {
              if (data.code == 200) {
                this.percentage1 = data.msg * 1
              } 
            })
    },
    upload_execel() {
      let res1 = this.beforeUpload_excel(this.uploadFiles1)
      if (!res1) {
        return
      }
      this.sub_btn = true;
      console.log(this.uploadFiles1)
      const flie = this.uploadFiles1[0].raw
      const formData = new FormData();
      console.log(flie)

      formData.append("file", flie);
      formData.append("set_car_model", this.set_car_model);
      formData.append("set_subjects", this.set_subjects);
      formData.append("set_class_name", this.set_class_name);
      console.log(formData)
      console.log(formData.get('file'));
      add_exercise_excel(formData).then(({ data }) => {
        if (data.code == 200) {
          this.per_status1 = true
          let timer = setInterval(() => {
            console.log('定时执行')
            this.set_progress(data.msg)
            if (this.percentage1 == 100){
              clearInterval(timer)
              this.sub_btn = false
              this.$message({
                type: 'success',
                message: '导入成功!'
              })
              this.centerDialogVisible1 = false
            }
          }, 1500)

        } else {
          this.$message({
            type: 'error',
            message: data.msg
          })
          this.sub_btn = false
        }
        
      })
    },
    download(val) {
      // console.log(val)
      const loading = this.$loading({
        lock: true,
        text: '拼命下载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      download_exercise({ params: { ...val } }).then(response => {
        if (!response) {
          return
        }
        const filename = decodeURI(response.headers['content-disposition'].split("; filename*=UTF-8''")[1])
        // const filename = response.headers['filename']
        // console.log(filename)
        // console.log(response.headers)
        // const url = window.URL.createObjectURL(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        loading.close()
      }).catch()


    },
    new_download(val) {
      // console.log(val)
      const loading = this.$loading({
        lock: true,
        text: '拼命下载中......',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      download_exercise({ params: { type: 'new' } }).then(response => {
        if (!response) {
          return
        }
        const filename = decodeURI(response.headers['content-disposition'].split("; filename*=UTF-8''")[1])
        // const filename = response.headers['filename']
        // console.log(filename)
        // console.log(response.headers)
        // const url = window.URL.createObjectURL(response.data)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        loading.close()
      }).catch()


    },
    handleClick(val) {
      console.log(val)
      this.video_url = val.video_url
      this.now_id = val.id
      this.centerDialogVisible2 = true
    },
    beforeUpload1(file_list) {
      if (file_list.length == 0) {
        return true
      }
      let file = file_list[0]
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["mp4"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 mp4 格式');
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 10240;

      if (!isLt2M) {
        this.$message.error('上传的视频大小不能超过 10GB');
        return false;
      }
      return true
    },

    beforeUpload_excel(file_list) {
      if (file_list.length == 0) {
        this.$message.error('请先选择要导入的文件!');
        return false;
      }
      let file = file_list[0]
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

      const whiteList = ["xlsx"];

      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传文件只能是 xlsx 格式');
        return false;
      }

      const isLt2M = file.size / 1024 / 1024 < 500;

      if (!isLt2M) {
        this.$message.error('上传的文件大小不能超过 20MB');
        return false;
      }
      return true
    },
    loadJsonFromFile1(file, fileList) {
      this.uploadFiles1 = fileList
    },
    jiqiao_close() {
      this.uploadFiles1 = []
      this.per_status1 = false
      this.percentage1 = 0
      this.getExercise()
    },
    put_jiqiao() {
      this.sub_btn = true;
      let res1 = this.beforeUpload1(this.uploadFiles1)
      let that = this
      // 校验附件是否符合要求
      if (res1 == true && this.uploadFiles1.length != 0) {

        secretkey({ params: { bucket: config.Bucket } }).then(({ data }) => {
          if (data.code == 200) {
            this.sub_btn = false;
            let subject_info = data
            var cos = new COS({
              // getAuthorization 必选参数
              getAuthorization: function (options, callback) {
                // 异步获取临时密钥
                // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
                // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
                // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
                callback({
                  TmpSecretId: data.cos_data.credentials.tmpSecretId,
                  TmpSecretKey: data.cos_data.credentials.tmpSecretKey,
                  SecurityToken: data.cos_data.credentials.sessionToken,
                  // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                  StartTime: data.cos_data.startTime, // 时间戳，单位秒，如：1580000000
                  ExpiredTime: data.cos_data.expiredTime, // 时间戳，单位秒，如：1580000000
                });
              }
            });
            console.log('开始上传')

            if (this.uploadFiles1.length == 1) {
              this.sub_btn = true
              that.per_status1 = true
              cos.uploadFile({
                Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                Key: 'subjects_video/' + data.sub_code + '.mp4',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                Body: this.uploadFiles1[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                onTaskReady: function (taskId) {                   /* 非必须 */
                  console.log(taskId);
                },
                onProgress: function (progressData) {           /* 非必须 */
                  console.log(JSON.stringify(progressData));
                  const percent = parseInt(progressData.percent * 10000) / 100;
                  const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                  console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                  that.percentage1 = percent
                },
                onFileFinish: function (err, data, options) {   /* 非必须 */
                  that.sub_btn = false
                  console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                  if (!err) {
                    console.log('图片上传成功')
                    update_iamge({ data: { file_type: 'subjects_video', code: subject_info.sub_code, 'id': that.now_id } }).then(({ data }) => {
                      that.$message.success(data.msg)
                    })
                  }
                },
              }, function (err, data) {
                that.sub_btn = false
                console.log(err || data);
              });

            }
          } else {
            that.$message.error('服务异常请重试!')
          }

        })
      }

    },
    del_file(code) {
      console.log(code)
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        update_iamge({ data: { file_type: 'del_subjects_video', 'id': this.now_id, 'code': code } }).then(({ data }) => {
          if (data.code == 200) {
            this.video_url = ''
            this.$message({
              type: 'success',
              message: data.msg
            });
          } else {
            this.$message({
              type: 'error',
              message: data.msg
            });
          }

        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    getExercise() {
      classification({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
        this.tableData = data.data
        this.total = data.count
        this.loading = false
      })
    },
    select_car(car_model){
      console.log(car_model)
      if (car_model == '三力'){
        this.options1 = [{
          value: '三力测试',
          label: '三力测试'
        },{
          value: '学法减分',
          label: '学法减分'
        }]
      } else {
        this.options1 = [
          {value: '科目一',
            label: '科目一'
          }, {
            value: '科目四',
            label: '科目四'
          }, {
            value: '满分',
            label: '满分'
          }, {
            value: '注销恢复',
            label: '注销恢复'
          }]
      }

    }

  },
  mounted() {
    classification({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
      this.tableData = data.data
      this.total = data.count
      this.loading = false
    })

  }
}
</script>
<style lang="less" scoped>
.manage {
  height: 90%;

  .manage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .common-tabel {
    position: relative;
    height: calc(100% - 1px);

    .pager {
      position: absolute;
      bottom: 0;
      right: 20px;
    }
  }
}
</style>