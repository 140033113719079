<template>

    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">

        <h3>{{ isCollapse ? '易点通' : '易点通管理后台' }}</h3>

        <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
        </el-menu-item>


        <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
            <template slot="title">
                <i :class="`el-icon-${item.icon}`"></i>
                <span slot="title">{{ item.label }}</span>
            </template>
            <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item @click="clickMenu(subItem)" :index="subItem.path">{{ subItem.label }}</el-menu-item>
            </el-menu-item-group>
        </el-submenu>



    </el-menu>

</template>



<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}

.el-menu {
    border-right: none;
    height: 100vh;
    h3 {
        color: #fff;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-weight: 400;
        border-bottom: 2px solid;
        border-image: linear-gradient(270deg, #a4af0c 0%, #0AA092 100%) 2 2 2 2;
    }
}
</style>

<script>
import Cookie from "js-cookie"

export default {
    data() {
        return {
            // isCollapse: false,
            // menuData: [
            //     {
            //         path: '/',
            //         name: 'home',
            //         label: '首页',
            //         icon: 's-home',
            //     },
            //     {
            //         path: '/admin_info',
            //         name: 'admin_info',
            //         label: '管理员信息',
            //         icon: 'user-solid',
            //     },
            //     {
            //         label: '软件管理',
            //         icon: 'monitor',
            //         children: [
            //                 {
            //                 path: '/app_user',
            //                 name: 'app_user',
            //                 label: '软件用户管理',
            //             },
            //             {
            //                 path: '/app_user_study',
            //                 name: 'app_user_study',
            //                 label: '用户学习情况',
            //             },

            //             {
            //                 path: '/app_user_order',
            //                 name: 'app_user_order',
            //                 label: '收入情况',
            //             },

            //             {
            //                 path: '/app_code',
            //                 name: 'app_code',
            //                 label: '软件激活码管理',
            //             },
            //         ]
            //     },
            //     {
            //         label: '微信管理',
            //         icon: 'chat-dot-round',
            //         children: [
            //                 {
            //                 path: '/user',
            //                 name: 'user',
            //                 label: '微信用户管理',
            //             },
            //             {
            //                 path: '/activation_code',
            //                 name: 'activation_code',
            //                 label: '微信激活码管理',
            //             },
            //         ]
            //     },
                
            //     {
            //         label: '习题库',
            //         icon: 'folder',
            //         children: [
            //             {
            //                 path: '/xiaoche',
            //                 name: 'xiaoche',
            //                 label: '小车',
            //             },
            //             {
            //                 path: '/motuo',
            //                 name: 'muoto',
            //                 label: '摩托车',
            //             },
            //             {
            //                 path: '/keche',
            //                 name: 'keche',
            //                 label: '客车',
            //             },
            //             {
            //                 path: '/huoche',
            //                 name: 'huoche',
            //                 label: '货车',
            //             },
            //         ]
            //     },
            //     {
            //         label: '题库管理',
            //         icon: 'folder-add',
            //         children: [
            //             {
            //                 path: '/answer_type',
            //                 name: 'answer_type',
            //                 label: '题型管理',
            //             },
            //             {
            //                 path: '/add_exercise',
            //                 name: 'add_exercise',
            //                 label: '添加习题',
            //             }
            //         ]
            //     }
            // ]

        };
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        clickMenu(item) {
            // console.log(item)
            if (this.$route.path !== item.path && !(this.$route.path === '/home' && (item.path === '/'))){
                this.$router.push(item.path)
            }
            
            this.$store.commit('selectMenu', item)

        }
    },
    computed: {
        // 权限验证以防后期需要(筛选需要显示的菜单栏)
        noChildren() {
            return this.menuData.filter(item => !item.children)
        },

        hasChildren() {
            return this.menuData.filter(item => item.children)
        },
        isCollapse() {
            return this.$store.state.tab.isCollapse
        },
        menuData() {
            // 判断cookie中是否存在菜单栏信息,没有则在store中获取
            return JSON.parse(localStorage.getItem("menu")) || this.$store.state.tab.menu
        }

    }
}
</script>