<template>
    <div class="header-container">
        <div class="l-content">
            <el-button @click="handleMenu" type="primary" :icon="set_icon" size="mini"
                style="margin-right: 20px"></el-button>

            <!-- 面包屑 -->
            <el-breadcrumb separator="/">
                <el-breadcrumb-item v-for="item in get_tablist" :key="item.pat" :to="{ path: item.path }">{{ item.label
                }}</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <div class="r-content">
            <el-dropdown :hide-on-click="false" @command="handleCommand">
                <span class="el-dropdown-link">
                    <img class="user"
                        :src="head_picture"
                        alt="头像">
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="user_info">个人中心</el-dropdown-item>
                    <el-dropdown-item command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

        </div>

        <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false" direction="ltr" :wrapperClosable=false>
            <div>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
            </div>
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
                <el-form-item label="上传头像">
                    <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".png,.jpg"
                        :limit=1 :on-change="loadJsonFromFile" :file-list="uploadFiles" :disabled="sub_btn" :on-remove="handleRemove">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传
                            <span>jpg</span>/<span>png</span>文件,且不超过<span>5MB</span>
                        </div>
                    </el-upload>
                    <el-progress :percentage="percentage" v-show="per_status"></el-progress>
                </el-form-item>

                <el-form-item label="用户名" prop="user_name" v-show="identity != '老师'">
                    <el-input placeholder="请输入新的用户名" v-model="ruleForm.user_name" ></el-input>
                </el-form-item>


                <el-form-item label="旧密码" prop="old_pwd">
                    <el-input placeholder="请输入旧密码" v-model="ruleForm.old_pwd"  show-password></el-input>
                </el-form-item>

                <el-form-item label="新密码" prop="new_pwd">
                    <el-input placeholder="密码长度8-16位, 必须包含: 大写字母、小写字母、数字" v-model="ruleForm.new_pwd"  show-password></el-input>
                </el-form-item>

                <el-form-item label="确认新密码" prop="new_pwd_again">
                    <el-input placeholder="密码长度8-16位, 必须包含: 大写字母、小写字母、数字" v-model="ruleForm.new_pwd_again"  show-password></el-input>
                </el-form-item>

                <el-form-item>
                    
                    <el-button @click="resetForm('ruleForm')" :disabled="sub_btn" icon="el-icon-close">取消</el-button>
                    <el-button type="primary" :disabled="sub_btn" icon="el-icon-check" :loading="sub_btn"
                        @click="submitForm('ruleForm')">确认</el-button>
                </el-form-item>
                
            </el-form>
            
        </el-drawer>

    </div>
</template>

<script>
import Cookie from 'js-cookie'
import COS from 'cos-js-sdk-v5';

import { change_user_info, update_iamge, admin_info } from '../api'

const config = {
    // 存储桶名称
    Bucket: 'user-picture-1257386772',
    // 地域
    Region: 'ap-nanjing'
}

export default {
    data() {
        return {
            drawer: false,
            uploadFiles: [],
            sub_btn: false,
            per_status: false,
            percentage: 0,
            identity: '',
            ruleForm:{
                user_name: '',
                old_pwd: '',
                new_pwd: '',
                new_pwd_again: ''
            },
            head_picture: ''
        }
    },
    methods: {

        handleMenu() {
            this.$store.commit('collapseMenu')
            this.$store.commit('getMentdirection')
        },
        handleCommand(command) {
            if (command === 'logout') {
                // 清楚cookie中的token
                Cookie.remove('admin_token')
                
                
                // 清楚cookie中的menu权限菜单信息
                // Cookie.remove('menu')
                localStorage.removeItem('menu')

                this.$router.push('/login')
            } else if (command === 'user_info') {
                this.drawer = true
            }
        },
        handleRemove(){
            this.uploadFiles=[]
        },
        loadJsonFromFile(file, fileList) {
            console.log(file, fileList)
            this.uploadFiles = fileList
        },
        resetForm(){
            this.drawer = false
            this.ruleForm={
                user_name: '',
                old_pwd: '',
                new_pwd: '',
                new_pwd_again: ''
            }
            this.uploadFiles=[]
            this.per_status=false
            this.percentage=0
        },
        submitForm(){
            console.log(this.ruleForm)
            change_user_info({ model: this.ruleForm, image: this.uploadFiles.length, bucket: config.Bucket }).then(({ data }) => {
                            console.log(data)
                            if (data.code == 200) {
                                this.sub_btn = false;
                                let subject_info = data
                                if (this.uploadFiles.length == 0) {
                                    this.$message.success(data.msg)
                                }
                                var cos = new COS({
                                    // getAuthorization 必选参数
                                    getAuthorization: function (options, callback) {
                                        // 异步获取临时密钥
                                        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
                                        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
                                        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
                                        callback({
                                            TmpSecretId: data.cos_data.credentials.tmpSecretId,
                                            TmpSecretKey: data.cos_data.credentials.tmpSecretKey,
                                            SecurityToken: data.cos_data.credentials.sessionToken,
                                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                                            StartTime: data.cos_data.startTime, // 时间戳，单位秒，如：1580000000
                                            ExpiredTime: data.cos_data.expiredTime, // 时间戳，单位秒，如：1580000000
                                        });
                                    }
                                });
                                console.log('开始上传')
                                console.log(this.uploadFiles)
                                let that = this
                                let image_name = 'head_picture/' + data.sub_code + '.jpg'
                                // // 开始上传文件
                                if (this.uploadFiles.length == 1) {
                                    // 按钮不可点击
                                    that.sub_btn = true
                                    // 显示进度条
                                    that.per_status = true
                                    cos.uploadFile({
                                        Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                                        Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                                        Key: image_name,  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                        Body: this.uploadFiles[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                                        onTaskReady: function (taskId) {                   /* 非必须 */
                                            console.log(taskId);
                                        },
                                        onProgress: function (progressData) {           /* 非必须 */
                                            console.log(JSON.stringify(progressData));
                                            const percent = parseInt(progressData.percent * 10000) / 100;
                                            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                                            console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                                            that.percentage = percent
                                            p = percent
                                        },
                                        onFileFinish: function (err, data, options) {   /* 非必须 */
                                            console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                                            if (!err) {
                                                console.log('图片上传成功')
                                                update_iamge({ data: { file_type: 'user_head_picture', code: image_name } }).then(({ data }) => {
                                                    that.$message.success(data.msg)
                                                })
                                            }
                                        },
                                    }, function (err, data) {
                                        that.sub_btn = false
                                        that.resetForm()
                                        console.log(err || data);
                                    });

                                }

                                
                            } else if (data.code == 202){
                                this.$message.success(data.msg);
                                Cookie.remove('token')
                                this.$router.push('/login')
                            } 
                            else {
                                this.sub_btn = false;
                                this.$message.error(data.msg);
                            }

                        }).catch((e) => { this.sub_btn = false; })

        }
    },
    computed: {
        set_icon() {
            return this.$store.state.tab.set_icon
        },
        get_tablist() {
            return this.$store.state.tab.tabsList
        }
    },
    mounted() {
        admin_info().then(({ data }) =>{
            this.head_picture = data.data.head_picture
            this.identity = data.data.identity
        })
    }
}

</script>

<style lang="less" scoped>
.header-container {
    padding: 0 20px;
    background-color: #333;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
        color: #fff;
        font-size: 16px;
        padding: 0 20px;
    }

    .r-content {
        .user {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
    }

    .l-content {
        display: flex;
        align-items: center;

        /deep/.el-breadcrumb__item {
            .el-breadcrumb__inner {
                &.is-link {
                    color: #fff;
                }
            }

            // 最后一个元素单数设置样式

            // &:last-child{
            //     .el-breadcrumb__inner{
            //         color: #fff;
            //     }
            // }



        }
    }
}</style>