<template>
    <div style="width:80%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="车辆类型" prop="car_model">
                        <el-select v-model="ruleForm.car_model" placeholder="请选择车辆类型"
                            @change="select_car(ruleForm.car_model)">
                            <el-option label="小车" value="小车"></el-option>
                            <el-option label="摩托车" value="摩托车"></el-option>
                            <el-option label="客车" value="客车"></el-option>
                            <el-option label="货车" value="货车"></el-option>
                            <el-option label="三力" value="三力"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="题库类别" prop="subjects" class="manage-header">
                        <el-cascader :options="options" :show-all-levels="true" @change="handleChange($event)"
                            ref="cascaderArr" clearable :disabled="subjectshow" :placeholder="subjecttitle">

                        </el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>


            <el-form-item label="题目类型" prop="answer_type">
                <el-radio-group v-model="ruleForm.answer_type" @input="set_answer_type">
                    <el-radio label="判断题"></el-radio>
                    <el-radio label="单选题"></el-radio>
                    <el-radio label="多选题"></el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="问题" prop="problem">
                <el-input type="textarea" v-model="ruleForm.problem"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="选项A" prop="option_a">
                        <el-input placeholder="请输入内容" v-model="ruleForm.option_a" clearable></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="选项B" prop="option_b">
                        <el-input placeholder="请输入内容" v-model="ruleForm.option_b" clearable></el-input>
                    </el-form-item>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="选项C" prop="option_c">
                        <el-input placeholder="请输入内容" v-model="ruleForm.option_c" clearable
                            :disabled="set_status"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="选项D" prop="option_d">
                        <el-input placeholder="请输入内容" v-model="ruleForm.option_d" clearable
                            :disabled="set_status"></el-input>
                    </el-form-item>
                </el-col>

            </el-row>




            <el-form-item label="问题答案" prop="answer">
                <el-checkbox-group v-model="ruleForm.answer">
                    <el-checkbox label="选型A" name="type"></el-checkbox>
                    <el-checkbox label="选项B" name="type"></el-checkbox>
                    <el-checkbox label="选项C" name="type" :disabled="set_status"></el-checkbox>
                    <el-checkbox label="选项D" name="type" :disabled="set_status"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-row>
                <el-col :span="12">
                    <el-form-item label="技巧" prop="jiqiao">
                        <el-input placeholder="请输入技巧" v-model="ruleForm.jiqiao" clearable></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="唯一标识" prop="uuid_code">
                        <el-input placeholder="请输入需要添加到特定题目位置的唯一标识" v-model="ruleForm.uuid_code" clearable></el-input>
                    </el-form-item>

                </el-col>

            </el-row>

            <el-row>
                <el-form-item label="提示关键字" prop="key_word">
                        <el-input placeholder="提示关键字,以 + 号分割。例如: 违法+事故" v-model="ruleForm.key_word" clearable></el-input>
                    </el-form-item>
            </el-row>



            <el-row class="upload">
                <el-col :span="12">
                    <el-form-item label="上传题目插图">
                        <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".png,.jpg,.gif"
                            :limit=1 :on-change="loadJsonFromFile" :file-list="uploadFiles" :disabled="sub_btn">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传
                                <span>jpg</span>/<span>png</span>/<span>gif</span>文件,且不超过<span>2MB</span>
                            </div>
                        </el-upload>
                        <el-progress :percentage="percentage" v-show="per_status"></el-progress>
                    </el-form-item>

                </el-col>
                <el-col :span="12">
                    <el-form-item label="上传题目视频">
                        <el-upload class="upload-demo" drag action="" multiple :auto-upload="false" accept=".mp4" :limit=1
                            :before-upload="beforeUpload1" :on-change="loadJsonFromFile1" :file-list="uploadFiles1"
                            :disabled="sub_btn">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传<span>mp4</span>文件,且不超过<span>500MB</span></div>
                        </el-upload>
                        <el-progress :percentage="percentage1" v-show="per_status1"></el-progress>
                    </el-form-item>
                </el-col>
            </el-row>



            <el-form-item>
                <el-button type="primary" :disabled="sub_btn" icon="el-icon-check" :loading="sub_btn"
                    @click="submitForm('ruleForm')">确认</el-button>
                <el-button @click="resetForm('ruleForm')" :disabled="sub_btn" icon="el-icon-refresh-right">清空</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>


<script>

import { searchSelect, addproblem, update_iamge } from '../api'

import COS from 'cos-js-sdk-v5';

const config = {
    // 存储桶名称
    Bucket: 'subject-picture-1257386772',
    // 地域
    Region: 'ap-nanjing'
}



export default {
    data() {
        return {
            // 存储科目可选类型状态
            subjectshow: true,
            subjecttitle: '请先选择车辆类型',
            // 存储科目选项
            options: [],

            // 单选题设置 C,D 状态
            set_status: false,

            // 定义存储图片文件
            uploadFiles: [],
            // 定义存储视频文件
            uploadFiles1: [],
            ruleForm: {
                car_model: '',
                subjects: '',
                answer_type: '',
                option_a: '',
                option_b: '',
                option_c: '',
                option_d: '',
                problem: '',
                answer: [],
                jiqiao: '',
                uuid_code: '',
                key_word: ''
            },
            // 上传进度
            percentage: 0,
            percentage1: 0,
            // 是否显示进度条
            per_status: false,
            per_status1: false,
            // 是否显示加载圈
            sub_btn: false,
            rules: {
                car_model: [
                    { required: true, message: '请选择车辆类型', trigger: 'blur' },
                ],
                subjects: [
                    { required: true, message: '请选择题库类别', trigger: 'change' }
                ],
                answer_type: [
                    { required: true, message: '请选择题目类型', trigger: 'change' }
                ],
                option_a: [
                    { required: true, message: '请填写选项A内容', trigger: 'change' }
                ],
                option_b: [
                    { required: true, message: '请填写选项B内容', trigger: 'change' }
                ],
                option_c: [
                    { required: true, message: '请填写选项C内容', trigger: 'change' }
                ],
                option_d: [
                    { required: true, message: '请填写选项D内容', trigger: 'change' }
                ],

                problem: [
                    { required: true, message: '请填写问题描述', trigger: 'change' }
                ],

                answer: [
                    { required: true, message: '请选择问题答案', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        submitForm(formName) {

            console.log(this.sub_btn)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let res = this.beforeUpload(this.uploadFiles)
                    let res1 = this.beforeUpload1(this.uploadFiles1)
                    let that = this
                    // 校验文件是否符合要求
                    if (res == true && res1 == true) {
                        console.log('保存题目')
                        this.sub_btn = true;
                        addproblem({ model: this.ruleForm, image: this.uploadFiles.length, video: this.uploadFiles1.length, bucket: config.Bucket }).then(({ data }) => {
                            console.log(data)
                            if (data.code == 200) {
                                this.sub_btn = false;
                                let subject_info = data
                                if (this.uploadFiles.length == 0 && this.uploadFiles1.length == 0) {
                                    this.$message.success(data.msg)
                                }
                                var cos = new COS({
                                    // getAuthorization 必选参数
                                    getAuthorization: function (options, callback) {
                                        // 异步获取临时密钥
                                        // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
                                        // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
                                        // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
                                        callback({
                                            TmpSecretId: data.cos_data.credentials.tmpSecretId,
                                            TmpSecretKey: data.cos_data.credentials.tmpSecretKey,
                                            SecurityToken: data.cos_data.credentials.sessionToken,
                                            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                                            StartTime: data.cos_data.startTime, // 时间戳，单位秒，如：1580000000
                                            ExpiredTime: data.cos_data.expiredTime, // 时间戳，单位秒，如：1580000000
                                        });
                                    }
                                });
                                console.log('开始上传')
                                console.log(this.uploadFiles)
                                // 开始上传文件
                                if (this.uploadFiles.length == 1) {
                                    // 按钮不可点击
                                    that.sub_btn = true
                                    // 显示进度条
                                    that.per_status = true
                                    cos.uploadFile({
                                        Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                                        Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                                        Key: data.sub_code + '/illustration.jpg',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                        Body: this.uploadFiles[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                                        onTaskReady: function (taskId) {                   /* 非必须 */
                                            console.log(taskId);
                                        },
                                        onProgress: function (progressData) {           /* 非必须 */
                                            console.log(JSON.stringify(progressData));
                                            const percent = parseInt(progressData.percent * 10000) / 100;
                                            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                                            console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                                            that.percentage = percent
                                            p = percent
                                        },
                                        onFileFinish: function (err, data, options) {   /* 非必须 */
                                            if (that.uploadFiles1.length == 0) {
                                                that.sub_btn = false
                                            }
                                            console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                                            if (!err) {
                                                console.log('图片上传成功')
                                                update_iamge({ data: { file_type: 'image', code: subject_info.sub_code } }).then(({ data }) => {
                                                    that.$message.success(data.msg)
                                                })
                                            }
                                        },
                                    }, function (err, data) {

                                        if (that.uploadFiles1.length == 0) {
                                            that.sub_btn = false
                                        }
                                        console.log(err || data);
                                    });

                                }

                                if (this.uploadFiles1.length == 1) {
                                    this.sub_btn = true
                                    that.per_status1 = true
                                    cos.uploadFile({
                                        Bucket: config.Bucket, /* 填入您自己的存储桶，必须字段 */
                                        Region: config.Region,  /* 存储桶所在地域，例如ap-beijing，必须字段 */
                                        Key: data.sub_code + '/' + data.sub_code + '.mp4',  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                        Body: this.uploadFiles1[0].raw, /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，非必须 */
                                        onTaskReady: function (taskId) {                   /* 非必须 */
                                            console.log(taskId);
                                        },
                                        onProgress: function (progressData) {           /* 非必须 */
                                            console.log(JSON.stringify(progressData));
                                            const percent = parseInt(progressData.percent * 10000) / 100;
                                            const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                                            console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                                            that.percentage1 = percent
                                        },
                                        onFileFinish: function (err, data, options) {   /* 非必须 */
                                            that.sub_btn = false
                                            console.log(options.Key + '上传' + (err ? '失败' : '完成'), data);
                                            if (!err) {
                                                console.log('图片上传成功')
                                                update_iamge({ data: { file_type: 'video', code: subject_info.sub_code } }).then(({ data }) => {
                                                    that.$message.success(data.msg)
                                                })
                                            }
                                        },
                                    }, function (err, data) {
                                        that.sub_btn = false
                                        console.log(err || data);
                                    });

                                }
                            } else {
                                this.sub_btn = false;
                                this.$message.error(data.msg);
                            }

                        }).catch((e) => { this.sub_btn = false; })

                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.uploadFiles = []
            this.uploadFiles1 = []
            this.per_status = false
            this.per_status1 = false
        },
        handleChange() {
            const checkedNode = this.$refs["cascaderArr"].getCheckedNodes();
            //console.log( checkedNode[0].data.label) ;  //获取当前点击节点的label值
            if (checkedNode.length !== 0) {
                const d = checkedNode[0].pathLabels;  //获取由label组成的数组
                const _str = d.join("/")
                this.ruleForm.subjects = _str
            } else {
                this.ruleForm.subjects = ''
            }

        },
        select_car(val) {
            console.log(val)
            searchSelect({ params: { model: this.ruleForm.car_model } }).then(({ data }) => {
                this.options = data.data
                this.subjectshow = false
            })
        },
        set_answer_type(val) {
            console.log('选择了题型', this.ruleForm.answer_type)
            if (this.ruleForm.answer_type == '判断题') {
                this.set_status = true
                delete this.rules.option_c
                delete this.rules.option_d
            } else {
                this.set_status = false
                this.rules['option_c'] = [{ required: true, message: '请填写选项C内容', trigger: 'change' }]
                this.rules['option_d'] = [{ required: true, message: '请填写选项D内容', trigger: 'change' }]
            }
        },
        beforeUpload(file_list) {
            if (file_list.length == 0) {
                return true
            }
            console.log(file_list)
            let file = file_list[0]
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

            console.log(fileSuffix, '文件后缀')

            const whiteList = ["jpg", "png", 'gif'];

            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 jpg, png, gif 格式');
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isLt2M) {
                this.$message.error('上传的图片大小不能超过 2MB');
                return false;
            }
            return true
        },
        beforeUpload1(file_list) {
            if (file_list.length == 0) {
                return true
            }
            let file = file_list[0]
            const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);

            const whiteList = ["mp4"];

            if (whiteList.indexOf(fileSuffix) === -1) {
                this.$message.error('上传文件只能是 mp4 格式');
                return false;
            }

            const isLt2M = file.size / 1024 / 1024 < 500;

            if (!isLt2M) {
                this.$message.error('上传的视频大小不能超过 500MB');
                return false;
            }
            return true
        },
        loadJsonFromFile(file, fileList) {
            console.log(file, fileList)
            this.uploadFiles = fileList
        },
        loadJsonFromFile1(file, fileList) {
            this.uploadFiles1 = fileList
        },
        random_str(){
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            let maxPos = chars.length;
            var code = '';
            for (let i = 0; i < 6; i++) {
                code += chars.charAt(Math.floor(Math.random() * maxPos));
            }

            return code

        }

    }
}
</script>

<style lang="less" scoped>
.manage-header {
    justify-content: space-between;
    align-items: center;

    .el-input {
        margin-left: 20px;

    }

    .el-button--primary {
        margin-left: 20px;
    }

    .el-cascader {
        width: 300px;
    }

    .el-form--inline {
        padding-top: 20px;
    }
}

.upload {
    span {
        color: red;
    }
}
</style>