<template>
    <el-row>
        <el-col :span="8" style="padding-right: 10px">
            <el-card class="box-card">
                <div class="user">
                    <img :src="user_info.head_picture" alt="头像">
                    <div class="userinfo">
                        <p class="name">{{ user_info.user_name }}</p>
                        <P class="access">{{ user_info.identity }}</P>
                    </div>

                </div>
                <div class='login_info'>
                    <p>上次登录时间: <span>{{ user_info.login_time }}</span></p>
                    <p>上次登录地点: <span>{{ user_info.login_address }}</span></p>
                </div>

            </el-card>
            <el-card v-show="identity == '成员'" style="margin-top: 20px; height: 520px;">
                    <div ref="echarts4" style="height: 520px;"></div>
            </el-card>
            <el-card style="margin-top: 20px; height: 520px;"  v-show="identity != '成员'">
                <el-table :data="tableData" style="width: 100%;"  height="500px">
                    <el-table-column v-for="(val, key) in tableLabel" :prop="key" :label="val" :key="key" />
                    <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                    <el-button @click="exam_pass(scope.row)" type="text" size="small">通过</el-button>
                    <el-button @click="exam_fail(scope.row)" type="text" size="small">挂科</el-button>
                    <el-button @click="exam_del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
                </el-table>
            </el-card>
        </el-col>

        <el-col :span="16" style="padding-left: 10xp">
            <div class="num">
                <el-card v-for="item in countData" :key="item.name" :body-style="{ display: 'flex', padding: 0 }">

                    <i class="icon" :class="`el-icon-${item.icon}`" :style="{ background: item.color }"></i>
                    <div class="detail">
                        <p class="price">{{ item.value }}</p>
                        <p class="desc">{{ item.name }}</p>
                    </div>

                </el-card>

            </div>
            <el-card style="height: 280px" v-show="identity == '管理员'">>
                <!-- 折线图部分 -->
                <div ref="echarts1" style="height:280px"></div>

            </el-card>

            <div class="graph" v-show="identity == '管理员'">
                <el-card>
                    <div ref="echarts2" style="height: 323px;"></div>
                </el-card>
                <el-card>
                    <div ref="echarts3" style="height: 323px;"></div>
                </el-card>
            </div>

            <el-card style="margin-top: 20px; height: 603px;" v-show="identity == '老师'">
                <el-table :data="tableData_fail" style="width: 100%;"  height="500px" >
                    <el-table-column v-for="(val, key) in tableLabe2" :prop="key" :label="val" :key="key" />
                </el-table>
            </el-card>

            <el-card style="margin-top: 10px; height: 300px;" v-show="identity == '成员'">
                <el-table :data="tableData_fail" style="width: 100%;"  height="500px" >
                    <el-table-column v-for="(val, key) in tableLabe2" :prop="key" :label="val" :key="key" />
                </el-table>
            </el-card>

            <el-card style="margin-top: 10px; height: 300px;"  v-show="identity == '成员'" >
                <el-table :data="tableData" style="width: 100%;"  height="500px">
                    <el-table-column v-for="(val, key) in tableLabel" :prop="key" :label="val" :key="key" />
                    <el-table-column fixed="right" label="操作" width="120">
                <template slot-scope="scope">
                    <el-button @click="exam_pass(scope.row)" type="text" size="small">通过</el-button>
                    <el-button @click="exam_fail(scope.row)" type="text" size="small">挂科</el-button>
                    <el-button @click="exam_del(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
                </el-table>
            </el-card>


        </el-col>
        <div class="release">
            <h1>更新后无效尝试更改这个标签的样式</h1>
        </div>
    </el-row>
</template>

<script>
import { getData , admin_info, set_exam_log, full_examination, full_examination_fail} from "../api"
import * as echarts from 'echarts'

export default {
    data() {
        return {
            tableData: [],
            tableData_fail: [],
            tableLabel: {
                name: '用户',
                car: '车型',
                subjects: '科目',
                on_time: '时间',
                complete_status: '状态',
            },
            tableLabe2: {
                name: '用户',
                car: '车型',
                subjects: '科目',
                on_time: '挂科时间',
                complete_status: '状态',
            },
            countData: [
                {
                    name: "今日新增学员",
                    value: '加载中...',
                    icon: "user",
                    color: "#2ec7c9",
                },
                {
                    name: "今日总收入",
                    value: '加载中...',
                    icon: "shopping-cart-full",
                    color: "#ffb980",
                },
                {
                    name: "当月新增软件学员数量",
                    value: '加载中...',
                    icon: "user-solid",
                    color: "#5ab1ef",
                },
                {
                    name: "当月总收入",
                    value: '加载中...',
                    icon: "money",
                    color: "#458B74",
                },
                {
                    name: "近一个月学员通过率",
                    value: '加载中...',
                    icon: "pie-chart",
                    color: "#CD5B45",
                },
                {
                    name: "软件学员总数量",
                    value: '加载中...',
                    icon: "chat-dot-round",
                    color: "#8B658B",
                },
            ],
            user_info: {
                user_name: '加载中...', 
                login_time: '加载中...', 
                login_address: '加载中...', 
                identity: '加载中...', 
                head_picture: ''
            },
            identity: ''
        }
    },
    methods:{
        exam_fail(val){
            console.log(val)
            console.log('挂科')
            set_exam_log({ params: { _id: val.id, set_type: 'fail' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },
        exam_pass(val){
            console.log(val)
            console.log('通过')
            set_exam_log({ params: { _id: val.id, set_type: 'pass' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },
        exam_del(val){
            console.log(val)
            console.log('删除')
            set_exam_log({ params: { _id: val.id, set_type: 'del' } }).then(({ data }) => {
                if (data.code == 200) {
                    this.search_exam()
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }

            })
        },
        search_exam(){
            full_examination().then(({ data }) => {
                if (data.code == 200) {
                    this.tableData = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }
                this.search_exam_fail()

            })
        },
        search_exam_fail(){
            full_examination_fail().then(({ data }) => {
                if (data.code == 200) {
                    this.tableData_fail = data.data
                } else {
                    this.$message({
                        type: 'error',
                        message: '网络异常请重试!'
                    });
                }

            })
        },
    },
    mounted() {
        this.search_exam(),
        getData().then(({ data }) => {
            const { top_right_corner } = data.data
            // this.tableData = tableData
            console.log(top_right_corner)
            this.countData[0].value = top_right_corner.today_add
            this.countData[1].value = top_right_corner.today_amount
            this.countData[2].value = top_right_corner.total_count
            this.countData[3].value = top_right_corner.total_amount
            this.countData[4].value = top_right_corner.pass_rate
            this.countData[5].value = top_right_corner.wechat_total

            // 基于准备好的dom,初始化echarts实例
            const echarts1 = echarts.init(this.$refs.echarts1)
            // 指定图标的配置项和数据
            var echarts1Option = {}
            // 处理数据xAxis
            const { orderData, userData, videoData } = data.data
            const xAxis = Object.keys(orderData.data[0])
            console.log(orderData)
            const xAxisData = {
                data: orderData.date
            }

            echarts1Option.xAxis = xAxisData
            echarts1Option.yAxis = {}
            echarts1Option.legend = {data: xAxis}

            echarts1Option.series = []

            xAxis.forEach(key => {
                echarts1Option.series.push({
                    name: key,
                    data: orderData.data.map(item => item[key]),
                    type: 'line'
                })
            })

            echarts1.setOption(echarts1Option)

             // 柱状图
            const echarts2 = echarts.init(this.$refs.echarts2)
            const eachrts2Option = {
                legend: {
                    // 图例文字颜色
                    textStyle: {
                    color: "#333",
                    },
                },
                grid: {
                    left: "20%",
                },
                // 提示框
                tooltip: {
                    trigger: "axis",
                },
                xAxis: {
                    type: "category", // 类目轴
                    data: userData.map(item => item.date),
                    axisLine: {
                    lineStyle: {
                        color: "#17b3a3",
                    },
                    },
                    axisLabel: {
                    interval: 0,
                    color: "#333",
                    },
                },
                yAxis: [
                    {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                        color: "#17b3a3",
                        },
                    },
                    },
                ],
                color: ["#2ec7c9", "#b6a2de"],
                series: [
                    {
                        name: '微信用户',
                        data: userData.map(item => item.new),
                        type: 'bar'
                    },
                    {
                        name: '软件用户',
                        data: userData.map(item => item.active),
                        type: 'bar'
                    }
                ],
            }
            echarts2.setOption(eachrts2Option)

            // 饼状图
            const echarts3 = echarts.init(this.$refs.echarts3)
            const eachrts3Option = {
                tooltip: {
                    trigger: "item",
                },
                color: [
                    "#0f78f4",
                    "#dd536b",
                    "#9462e5",
                    "#a6a6a6",
                    "#e1bb22",
                    "#39c362",
                    "#3ed1cf",
                ],
                series: [
                    {
                        data: videoData,
                        type: 'pie'
                    }
                ],
            }
            echarts3.setOption(eachrts3Option)

            const echarts4 = echarts.init(this.$refs.echarts4)
            const eachrts4Option = {
                tooltip: {
                    trigger: "item",
                },
                color: [
                    "#0f78f4",
                    "#dd536b",
                    "#9462e5",
                    "#a6a6a6",
                    "#e1bb22",
                    "#39c362",
                    "#3ed1cf",
                ],
                series: [
                    {
                        data: videoData,
                        type: 'pie'
                    }
                ],
            }
            echarts4.setOption(eachrts4Option)

        })

        admin_info().then(({ data }) =>{
            this.user_info = data.data
            this.identity = data.identity
        })

        

    }
}

</script>

<style lang="less" scoped>
.user {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;

    img {
        margin-right: 40px;
        width: 150px;
        height: 150px;
        border-radius: 20%;

    }

    .userinfo {
        .name {
            font-size: 32px;
            margin-bottom: 10px;
        }

        .access {
            color: #999999;
        }
    }
}

.login_info {
    p {

        line-height: 28px;
        font-size: 14px;
        color: #999999
    }

    span {
        color: #666666;
        margin-left: 60px;
    }
}

.num {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;


    .el-card {
        width: 32%;
        margin-bottom: 20px;
    }


    .icon {
        width: 80px;
        height: 80px;
        font-size: 30px;
        line-height: 80px;
        text-align: center;
        color: #fff;
    }

    .detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 15px;

        .price {
            font-size: 30px;
            margin-bottom: 10px;
            line-height: 30px;
            height: 30px;

        }

        .desc {
            font-size: 14px;
            color: #999;
            text-align: center;
        }
    }
}

.graph {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .el-card {
        width: 48%;
        height: 323px;
    }
}

.release{
    background-color:gold;
    display: none;
}
</style>