import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '../views/home.vue'
import user from '../views/user.vue'
import main from '../views/main.vue'
import xiaoche from '../views/xiaoche.vue'
import motuo from '../views/motuo.vue'
import huoche from '../views/huoche.vue'
import keche from '../views/keche.vue'
import practice from '../views/practice.vue'
import practice_copy from '../views/practice_copy.vue'
import login from "../views/login.vue"
import add_exercise from "../views/addexercise.vue"
import answer_type from '../views/answer_type.vue'
import activation_code from "../views/activation_code.vue"
import practice_weixin from "../views/practice_weixin.vue"
import app_user from "../views/app_user.vue"
import app_code from "../views/app_code.vue"
import app_user_study from "../views/app_user_study.vue"
import app_user_order from "../views/app_user_order.vue"
import admin_info from "../views/admin_user.vue"
import redirection_login from "../views/redirection_login.vue"
import phone from "../views/phone.vue"

Vue.use(VueRouter)

// 1. 定义 (路由) 组件。
// 可以从其他文件 import 进来
// const Foo = { template: '<div>foo</div>' }
// const Bar = { template: '<div>bar</div>' }

// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
const routes = [
    {
        'path': '/',
        component: main,
        name: 'main',
        redirect: '/home',
        children: [
            // 参数说明 path：二级路由地址；component: 映射视图文件; meta.keepalive: 是否进行页面缓存
            // { path: 'home', component: home, meta: { keepAlive: false } },
            // { path: 'user', component: user, meta: { keepAlive: true } },
            // { path: 'activation_code', component: activation_code, meta: { keepAlive: false } },
            // { path: 'xiaoche', component: xiaoche, meta: { keepAlive: true } },
            // { path: 'motuo', component: motuo, meta: { keepAlive: true } },
            // { path: 'huoche', component: huoche, meta: { keepAlive: true } },
            // { path: 'keche', component: keche, meta: { keepAlive: true } },

            // { path: 'add_exercise', component: add_exercise, meta: { keepAlive: true } },
            // { path: 'answer_type', component: answer_type, meta: { keepAlive: true } },
            // { path: 'app_user', component: app_user, meta: { keepAlive: false } },
            // { path: 'app_code', component: app_code, meta: { keepAlive: false } },
            // { path: 'app_user_study', component: app_user_study, meta: { keepAlive: false } },
            // { path: 'app_user_order', component: app_user_order, meta: { keepAlive: false } },
            // { path: 'admin_info', component: admin_info, meta: { keepAlive: false } },

        ]
    },
    {
        'path': '/practice',
        component: practice
    },
    {
        'path': '/practice_copy',
        component: practice_copy
    },
    {
        'path': '/practice_weixin',
        name: 'practice_weixin',
        component: practice_weixin
    },
    {
        'path': '/login',
        name: 'login',
        component: login
    },
    {
        'path': '/redirection_login',
        name: 'redirection_login',
        component: redirection_login
    },
    {
        'path': '/phone_add_some_code',
        name: 'phone',
        component: phone
    }

]

// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    mode: 'hash',
    routes // (缩写) 相当于 routes: routes
})

export default router