<template>
    <div class="manage">
      <el-dialog title="新增激活码" :visible.sync="dialogVisible" width="50%" @close="handleClose()">
        <!-- 用户的表单信息 -->
        <el-form ref="form" :rules="rules" :inline="true" :model="form" label-width="80px">
  
  
          <el-form-item label="有效期" prop="validity_period">
            <el-input placeholder="请输入有效期限(天)" v-model="form.validity" min="1" oninput ="value=value.replace(/[^\d]/g,'')"></el-input>
          </el-form-item>

          <el-form-item label="可做题型" prop="activation">
            <el-cascader
                :options="options"
                :props="props"
                collapse-tags
                clearable
                v-model="form.activation">
            </el-cascader>
            
          </el-form-item>
          

          <el-form-item label="备注" prop="remarks">
            <el-input placeholder="备注信息" v-model="form.remarks" ></el-input>
          </el-form-item>
        </el-form>
  
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </el-dialog>
      <div class="manage-header">
  
        <!-- form搜索区域 -->
        <el-form :inline="true" :model="userForm">
          <el-form-item>
            <el-input placeholder="激活码" v-model="userForm.code" clearable ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="备注" v-model="userForm.remarks" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
          </el-form-item>
        </el-form>
  
        <el-button @click="handleAdd" type="primary">
          + 新增
        </el-button>
      </div>
      <div class="common-tabel">
        <el-table stripe height="90%" :data="tableData" style="width: 100%" v-loading="loading"
          element-loading-text="拼命加载中">
          <el-table-column type="index" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="code" label="激活码" width="200">
          </el-table-column>
          <el-table-column prop="xiaoche" label="小车" width="200">
          </el-table-column>
          <el-table-column prop="motuo" label="摩托车" width="200">
          </el-table-column>
          <el-table-column prop="keche" label="客车" width="200">
          </el-table-column>
          <el-table-column prop="huoche" label="货车" width="200">
          </el-table-column>
          <el-table-column prop="sanli" label="三力" width="100">
          </el-table-column>
          <el-table-column prop="start_time" label="创建时间" width="200">
          </el-table-column>
          <el-table-column prop="end_time" label="到期时间" width="200">
          </el-table-column>
          <el-table-column prop="validity" label="有效期/天" width="200">
          </el-table-column>
          <el-table-column prop="remarks" label="备注信息" width="200">
          </el-table-column>
          <el-table-column prop="status" label="状态" width="200">
          </el-table-column>
          <el-table-column prop="name" label="用户昵称" width="200">
          </el-table-column>

          <el-table-column prop="addr" label="操作" width="350" fixed="right">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="handleClick(scope.row)"><i class="el-icon-copy-document"></i>复制</el-button>
              <el-button type="warning" size="mini" @click="upload(scope.row)"><i class="el-icon-open"></i>禁/开</el-button>
              <el-button type="success" size="mini" @click="remark(scope.row)"><i class="el-icon-edit"></i>备注</el-button>
              <el-button type="danger" size="mini" @click="handleDelete(scope.row)"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage1"
            :page-sizes="[10, 20, 30, 40, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </template>
  <script>
  // import { getUser, addUser, editUser, delUser } from '../api'
  import { activation, delactivation,  addactivation, update_activation, update_wx_code_remark } from '../api'
  
  const config = {
    // 存储桶名称
    Bucket: 'subject-picture-1257386772',
    // 地域
    Region: 'ap-nanjing'
  }
  
  
  export default {
    data() {
      return {
        dialogVisible: false,
        loading: true,
        currentPage1: 1,
        video_url: '',
        uploadFiles1: [],
        sub_btn: false,
        // 上传进度
        percentage1: 0,
        // 是否显示进度条
        per_status1: false,
        // 当前内容的
        now_id: '',
        form: {
            validity: '',
            remarks: '',
            activation: '',
        },
        rules: {
        },
        props: { multiple: true },
        options: [{
          value: 1,
          label: '小车',
          children: [{
            value: 2,
            label: '科目一',
          }, {
            value: 5,
            label: '科目四',
          }, {
            value: 6,
            label: '满分',
          },{
            value: 7,
            label: '注销恢复',
          }]
        }, {
          value: 8,
          label: '摩托车',
          children: [{
            value: 9,
            label: '科目一',
          }, {
            value: 10,
            label: '科目四',
          }, {
            value: 11,
            label: '满分',
          },{
            value: 12,
            label: '注销恢复',
          }]
        },{
          value: 13,
          label: '客车',
          children: [{
            value: 14,
            label: '科目一',
          }, {
            value: 15,
            label: '科目四',
          }, {
            value: 16,
            label: '满分',
          },{
            value: 17,
            label: '注销恢复',
          }]
        },{
          value: 18,
          label: '货车',
          children: [{
            value: 19,
            label: '科目一',
          }, {
            value: 20,
            label: '科目四',
          }, {
            value: 21,
            label: '满分',
          },{
            value: 22,
            label: '注销恢复',
          }]
        },{
          value: 23,
          label: '三力',
          children: [{
            value: 24,
            label: '三力测试',
          },
          {
            value: 25,
            label: '学法减分',
          }]
        }],
        tableData: [],
        modalType: 0, // 0表示新增的弹窗， 1表示编辑
        total: 0, //当前的总条数
        pageData: {
          page: 1,
          limit: 10
        },
        userForm: {
          code: '',
          remarks: ''
        },
        centerDialogVisible2: false,
      }
    },
    methods: {
      // 提交用户表单
      submit() {
        this.$refs.form.validate((valid) => {
          console.log(valid, 'valid')
          console.log(this.form)
          if (valid) {
            // 后续对表单数据的处理
            if (this.modalType === 0) {
                addactivation({ data: this.form }).then(({ data }) => {
                if (data.code == 200) {
                  this.$message({
                    type: 'success',
                    message: data.msg
                  })
                  this.dialogVisible = false
                } else {
                  this.$message({
                    type: 'error',
                    message: data.msg
                  })
                }
              })
            }
            this.getList()
  
            // 清空表单的数据
            this.$refs.form.resetFields()
            // 关闭弹窗
            // this.dialogVisible = false
          }
        })
      },
      // 弹窗关闭的时候
      handleClose() {
        this.$refs.form.resetFields()
        this.dialogVisible = false
      },
      cancel() {
        this.handleClose()
      },
      handleDelete(row) {
        this.$confirm('此操作将永久删除该激活码,并使已激活的用户功能失效, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delactivation({ params: row }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
  
            // 重新获取列表的接口
            this.getList()
          })
  
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      handleAdd() {
        this.modalType = 0
        this.dialogVisible = true
      },
      // 获取列表的数据
      getList() {
        // 获取的列表的数据
        activation({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
          console.log(data)
          this.tableData = data.data
  
          this.total = data.count || 0
        })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageData.limit = val
        this.getList()
  
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageData.page = val
        console.log(this.pageData)
        this.getList()
  
      },
      // 选择页码的回调函数
      handlePage(val) {
        // console.log(val, 'val')
        this.pageData.page = val
        this.getList()
      },
      // 列表的查询
      onSubmit() {
        // this.pageData.page = 1
        this.getList()
      },
      upload(row) {
        update_activation({ params: row }).then(({ data }) => {
            if (data.code == 200) {
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
  
            // 重新获取列表的接口
            this.getList()
          })
      },
      handleClick(val) {
        console.log(val)
        console.log('复制')
        var input = document.createElement("input"); // 创建input对象
        input.value = val.code; // 设置复制内容
        document.body.appendChild(input); // 添加临时实例
        input.select(); // 选择实例内容
        document.execCommand("Copy"); // 执行复制
        document.body.removeChild(input); // 删除临时实例
        this.$message.success('复制成功!')

      },
      getExercise() {
        activation({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
          this.tableData = data.data
          this.total = data.count
          this.loading = false
        })
      },
      remark(val) {
            console.log(val)
            this.$prompt('请输入需要修改的内容', '修改备注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
              update_wx_code_remark({ data: val, remark: value }).then(({ data }) => {
                    if (data.code == 200) {
                        this.getExercise()
                        this.$message({
                            type: 'success',
                            message: data.msg
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: data.msg
                        });
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消更改'
                });
            });

        },
  
    },
    mounted() {
        activation({ params: { ...this.userForm, ...this.pageData } }).then(({ data }) => {
        console.log(data)
        this.tableData = data.data
        this.total = data.count
        this.loading = false
      })
  
    }
  }
  </script>
  <style lang="less" scoped>
  .manage {
    height: 100%;
  
    .manage-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .common-tabel {
      position: relative;
      height: calc(100% - 62px);
  
      .pager {
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
  }
  </style>