<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true" type="card">
            <el-tab-pane name="first">
                <span slot="label"><i class="el-icon-chat-dot-round"></i> 创建微信激活码</span>
                <div style="padding: 20px">
                    <el-form ref="form" :model="form" label-width="80px" label-position="top">
                        <el-form-item label="有效期:">
                            <el-input placeholder="请输入有效期限(天)" v-model="form.validity" min="1"
                                oninput="value=value.replace(/[^\d]/g,'')" prop="validity" clearable
                                prefix-icon="el-icon-time"></el-input>
                        </el-form-item>
                        <el-form-item label="可做题型:">
                            <el-cascader :options="options" :props="props" collapse-tags clearable
                                v-model="form.activation" style="width: 100%;" prop="validactivationity">
                            </el-cascader>
                        </el-form-item>
                        <el-form-item label="备注:">
                            <el-input placeholder="备注信息" v-model="form.remarks" prop="remarks" clearable
                                prefix-icon="el-icon-tickets"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submit" style="width: 100%;">确 定</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="reset" style="width: 100%;">清 空</el-button>
                        </el-form-item>
                    </el-form>
                </div>


            </el-tab-pane>
            <el-tab-pane name="second">
                <span slot="label"><i class="el-icon-monitor"></i> 创建软件激活码</span>
                <div style="padding: 20px">
                    <el-form ref="form" :model="form1" label-width="80px" label-position="top">
                        <el-form-item label="备注:">
                            <el-input placeholder="备注信息" v-model="form1.remarks" prop="remarks" clearable
                                prefix-icon="el-icon-tickets"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleAdd" style="width: 100%;">确 定</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="reset1" style="width: 100%;">清 空</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane name="three">
                <span slot="label"><i class="el-icon-timer"></i>增加学时</span>
                <div style="padding: 20px">
                    <el-form ref="form" :model="form2" label-width="80px" label-position="top">
                        <el-form-item label="电话:">
                            <el-input placeholder="账号信息" v-model="form2.openid" prop="电话" clearable maxlength="11"
                                show-word-limit prefix-icon="el-icon-phone"></el-input>
                        </el-form-item>
                        <el-form-item label="学时:" prop="period">
                            <el-input placeholder="请输入需要增加的学时(小时单位)" v-model="form2.period" clearable></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleAdd1" style="width: 100%;">确 定</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="reset2" style="width: 100%;">清 空</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane name="four">
                <span slot="label"><i class="el-icon-user-solid"></i>VIP延时</span>
                <div style="padding: 20px">
                    <el-table height="90%" :data="tableData" style="width: 100%" v-loading="loading"
                        element-loading-text="拼命加载中" :row-style="{ height: '10px' }" highlight-current-row
                        :row-class-name="tableRowClassName">
                        <el-table-column fixed label="微信头像" width="70">
                            <template slot-scope="scope">
                                <el-image style="width: 50px; height: 50px; margin-bottom:-4px"
                                    :src="scope.row.headimgurl">
                                </el-image>
                            </template>
                        </el-table-column>
                        <el-table-column type="index" label="序号" width="80">
                            <template slot-scope="scope">
                                {{ scope.$index + (pageData.page - 1) * pageData.limit + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="nickname" label="微信昵称" width="80">
                        </el-table-column>
                        <!-- <el-table-column prop="out_trade_no" label="订单编号" width="300">
                        </el-table-column> -->
                        <!-- <el-table-column prop="on_time" label="创建时间" width="220">
                        </el-table-column> -->
                        <el-table-column prop="trade_state_desc" label="交易状态" width="150">
                        </el-table-column>
                        <el-table-column prop="success_time" label="交易时间" width="220">
                        </el-table-column>
                        <el-table-column prop="payer_total" label="支付金额" width="150">
                        </el-table-column>
                        <el-table-column prop="buy_vip" label="套餐内容" width="150">
                        </el-table-column>
                        <el-table-column prop="days" label="天数" width="150">
                        </el-table-column>
                        <!-- <el-table-column prop="state" label="是否返利" width="100">
                        </el-table-column> -->
                        <el-table-column fixed="right" label="操作" width="60">
                            <template slot-scope="scope">
                                <el-button @click="set_day(scope.row)" type="text" size="small">编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pager">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>


<script>
import { addactivation, create_code, login, update_app_user, get_vip_order, extend_vip} from '../api'

import Cookie from 'js-cookie'

export default {
    data() {
        return {
            activeName: 'first',
            form: {
                validity: '',
                remarks: '',
                activation: '',
            },
            form1: {
                remarks: '',
            },
            form2: {
                openid: '',
                period: ''
            },
            props: { multiple: true },
            rules: {
            },
            options: [{
                value: 1,
                label: '小车',
                children: [{
                    value: 2,
                    label: '科目一',
                }, {
                    value: 5,
                    label: '科目四',
                }, {
                    value: 6,
                    label: '满分',
                }, {
                    value: 7,
                    label: '注销恢复',
                }]
            }, {
                value: 8,
                label: '摩托车',
                children: [{
                    value: 9,
                    label: '科目一',
                }, {
                    value: 10,
                    label: '科目四',
                }, {
                    value: 11,
                    label: '满分',
                }, {
                    value: 12,
                    label: '注销恢复',
                }]
            }, {
                value: 13,
                label: '客车',
                children: [{
                    value: 14,
                    label: '科目一',
                }, {
                    value: 15,
                    label: '科目四',
                }, {
                    value: 16,
                    label: '满分',
                }, {
                    value: 17,
                    label: '注销恢复',
                }]
            }, {
                value: 18,
                label: '货车',
                children: [{
                    value: 19,
                    label: '科目一',
                }, {
                    value: 20,
                    label: '科目四',
                }, {
                    value: 21,
                    label: '满分',
                }, {
                    value: 22,
                    label: '注销恢复',
                }]
            }, {
                value: 23,
                label: '三力',
                children: [{
                    value: 24,
                    label: '三力测试',
                }]
            }],
            selectContent: '',
            password: '',
            default_account: '18897922322',
            tableData: [],
            pageData: {
                page: 1,
                limit: 10
            },
            userForm: {
                code: '',
                state: ''
            },
            loading: false,
            currentPage1: 1,
            total: 0
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        reset() {
            this.form = {
                validity: '',
                remarks: '',
                activation: '',
            }
        },
        reset1() {
            this.form1 = {
                remarks: '',
            }
        },
        reset2() {
            this.form2 = {
                openid: '',
                period: ''
            }
        },
        submit() {
            this.$refs.form.validate((valid) => {
                console.log(valid, 'valid')
                console.log(this.form)
                if (valid) {
                    // 后续对表单数据的处理
                    addactivation({ data: this.form }).then(({ data }) => {
                        if (data.code == 200) {
                            this.open1(data.set_phone)
                        } else {
                            this.$message({
                                type: 'error',
                                message: data.msg
                            })
                        }
                    })
                }
            })
        },
        open1(code) {
            this.$alert(code, '激活码', {
                confirmButtonText: '确定',
                type: 'success ',
                center: true,
                showClose: false,
                closeOnClickModal: false,
                callback: action => {
                    const res = this.copyText(code)
                    console.log(res)
                    if (res) {
                        this.$message({
                            type: 'success',
                            message: `复制成功: ${code}`
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: `复制失败: ${code}`
                        });
                    }

                }
            })
        },
        copyText(text) {
            // 动态创建 textarea 标签
            const textarea = document.createElement('textarea')
            // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
            textarea.readOnly = 'readonly'
            textarea.style.position = 'absolute'
            textarea.style.left = '-9999px'
            // 将要 copy 的值赋给 textarea 标签的 value 属性
            textarea.value = text
            // 将 textarea 插入到 body 中
            document.body.appendChild(textarea)
            // 选中值并复制
            textarea.select()
            const result = document.execCommand('Copy')
            document.body.removeChild(textarea)
            return result
        },
        handleAdd() {
            create_code({ params: this.form1 }).then(({ data }) => {
                console.log(data.code)
                if (data.code == 200) {
                    this.open1(data.set_phone)
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    });
                }
            })
        },
        handleAdd1() {
            update_app_user({ data: this.form2 }).then(({ data }) => {
                if (data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: data.msg
                    })
                } else {
                    this.$message({
                        type: 'error',
                        message: data.msg
                    })
                }
            })
        },
        check_box() {
            this.$prompt('请输入管理员密码', '身份验证', {
                confirmButtonText: '确定',
                center: true,
                inputType: 'password',
                inputPlaceholder: '请输入' + this.default_account + '密码',
                showClose: false,
                showCancelButton: false,
                closeOnClickModal: false,
                inputErrorMessage: '输入不能为空',
                inputValidator: (value) => {
                    if (!value) {
                        return '输入不能为空'
                    }
                    if (value) {
                        this.password = value
                    }
                },

                beforeClose: (action, instance, done) => {
                    if (action == 'confirm') {
                        if (this.password != '') {
                            console.log('进来了')
                            let loginForm = {
                                username: this.default_account,
                                password: this.password,
                                code: '',
                                checked: false,
                            }
                            login({ data: loginForm }).then(({ data }) => {
                                if (data.code == 200) {
                                    Cookie.set('admin_token', data.access_token)
                                    this.getList()
                                    this.$message.success('验证成功!')
                                    done()
                                } else if (data.code == 500) {
                                    this.$message.error('密码错误!')
                                    return '输入不能为空'
                                }
                            })
                        } else {
                            this.$message({
                                type: 'error',
                                message: '输入不能为空',
                                duration: 2000
                            })
                        }
                    }

                }
            })
        },
        // 获取列表的数据
        getList() {
            // 获取的列表的数据
            get_vip_order({ params: {...this.userForm, ...this.pageData } }).then(({ data }) => {
            console.log(data)
            this.tableData = data.data
    
            this.total = data.count || 0
            })
        },
        handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageData.limit = val
        this.getList()
  
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pageData.page = val
        console.log(this.pageData)
        this.getList()
  
      },
      // 选择页码的回调函数
      handlePage(val) {
        // console.log(val, 'val')
        this.pageData.page = val
        this.getList()
      },
      set_day(val) {
        console.log(val)
        this.$prompt('请输入需要增加的天数', '修改VIP期限', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          extend_vip({ data: val, price: value }).then(({ data }) => {
            if (data.code == 200) {
              this.getList()
              this.$message({
                type: 'success',
                message: data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消更改'
          });
        });
      },
      tableRowClassName({row, rowIndex}) {
        // 根据状态显示不同的行样式
        // console.log(row)
        // console.log(rowIndex)
        if (row.trade_state_desc === '支付成功') {
          return 'warning-row';
        }
        return '';
      },

    },
    mounted() {
        this.check_box()

    }
};
</script>

<style>
@media screen and (max-width: 750px) {
    .el-message-box {
        width: 60% !important;
    }
}
</style>