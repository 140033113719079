<template>
    <commonCar :car_model="car_model" />
</template>

<script>
import commonCar from '../components/commonCar.vue'

export default {
data() {
    return {
        // 默认车辆类型
        car_model: {
            model: '小车'
        },
    }
},
components: {
    commonCar
},
}

</script>